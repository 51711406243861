import {useEffect, useState} from "react";
import Keycloak from "keycloak-js"
import {useRef} from "react";

const keycloakUrl= process.env.REACT_APP_KEYCLOAK_URL;
const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;

// TODO: Diese Werte müssen noch extern aus dem Environment geladen werden
const client = new Keycloak({
    url: keycloakUrl,
    realm: "master",
    clientId: keycloakClientId,
    clientSecret: "e6FwOGkfgf5W0TjCT8TsYEZf3q31QjY8",
})

// userObj represents the most important information of the user token
const userObj = {
    id: "",
    roles: [],
    signature: "",
    userName: "",
}

// userRoles represents the available user roles
export const userRoles = {
    manufacturerEngineer: "manufacturer-engineer",
    serviceManager: "service-manager",
    technician: "technician"
}

// hasRole checks if a user has the specific role
export const hasRole = (roles, validateRole) => {
    return roles.includes(validateRole)
}

const useAuth = () => {
    const isRun = useRef(false)
    const [isLogin, setLogin] = useState(false)
    const [token, setToken] = useState(null)
    const [parsedToken, setParsedToken] = useState(null)
    const [user, setUser] = useState(userObj)

    useEffect(() => {
        // Return if authentication is already executed
        if(isRun.current) return

        // Redirect to login page
        isRun.current = true

        client
            .init({
                onLoad: "login-required",
            })
            .then((res) => {
                setLogin(res)
                setToken(client.token)
                const parsed = JSON.parse(atob(client.token.split('.')[1]))
                setParsedToken(parsed)
                setUser({...user,
                    id: parsed.sub,
                    roles: parsed.realm_access.roles,
                    signature: String(parsed.given_name).charAt(0) + String(parsed.family_name,).charAt(0),
                    userName: parsed.given_name + " " + parsed.family_name,
                })
            })
            .catch((err) => {
                console.log("[Keycloak session] failed to initialize:", err)
            })

    }, [])

    return [isLogin, token, parsedToken, user]
}

export default useAuth;