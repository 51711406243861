import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, notification, Upload } from 'antd';
import {useEffect, useState} from "react";
import { createNewTicket, fetchApi, getApi} from '../../utils/RequestBuilder';
import ManufacturerDropdown from './ManufacturerDropdown';
import AssetTypeDropdown from './AssetTypeDropdown';


function AddAssetModal({isOpen, auth, workstation_id, callbackFct, selectId, setMenuItemKey, workstation_name}) {
    const { t } = useTranslation();

    const hostName = process.env.REACT_APP_HOST_NAME

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [form] = Form.useForm()
    const [manufacturerList, setManufacturerList] = useState([])
    const [selectedManufacturerId, setSelectedManufacturerId] = useState("");
    const [selectedManufacturerName, setSelectedManufacturerName] = useState("");
    const [selectionInManufacturerList, setSelectionInManufacturerList] = useState(false);
    const [assetTypeList, setAssetTypeList] = useState([]);
    const [selectedAssetTypeId, setSelectedAssetTypeId] = useState("");
    const [selectedAssetTypeName, setSelectedAssetTypeName] = useState("");
    const [selectionInAssetTypeList, setSelectionInAssetTypeList] = useState("");

    /* const handleImageSelected = (blob) => {
        console.log("Aufruf in Modal", blob)
    } */

    useEffect(() =>{
        setSelectionInManufacturerList(manufacturerList.some(manufacturer => manufacturer.id === selectedManufacturerId))
    }, [selectedManufacturerId, selectedManufacturerName])

    useEffect (() => {
        if (selectionInManufacturerList) {loadAssetTypes()}
    }, [selectionInManufacturerList])

    useEffect(() =>{
        setSelectionInAssetTypeList(assetTypeList.some(item => item.asset_types.some(asset => asset.id === selectedAssetTypeId)))
    }, [selectedAssetTypeId, selectedAssetTypeName])

    const [fileList, setFileList] = useState([]);

    async function loadManufacturers() {
        try {
            const jsonData = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/manufacturers")
            setManufacturerList(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    async function loadAssetTypes() {
        try {
            const jsonData = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/manufacturers/" + selectedManufacturerId + "/asset-types")
            setAssetTypeList(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    const handleOk = async () => {
        form.validateFields().then(async () => {
            const formValues = form.getFieldsValue();
            const assetInformationArray = createAssetArray(formValues);
            
            try {
               /*  const onDownloadCallback = (blob) => {
                    console.log(blob);
                } */
                 //onDownloadCropClick();   
                setMenuItemKey(selectId)
                handleAssetCreation(auth, assetInformationArray)
                // sendNewMessage2(auth, "/assets", assetInformationArray, fileList[0].originFileObj)
                setIsModalOpen(false)
                form.resetFields();
                //await UploadAsset({ onDownload: onDownloadCallback});
            } 
            catch (error) {
                console.error("Error downloading cropped image");
            }
        });

        
    };

    function createAssetArray(formData) {
        //decide whether a known or new manufacturer
        let manufacturerNameToSend;
        let manufacturerIdToSend;
        if (selectedManufacturerId === selectedManufacturerName) {
            manufacturerNameToSend = selectedManufacturerName;
            manufacturerIdToSend = 0;
            //selectionInManufacturerList ? 
        } else {
            if (selectionInManufacturerList) {
                manufacturerNameToSend = "" ;
                manufacturerIdToSend = selectedManufacturerId;
            } else {
                manufacturerNameToSend = selectedManufacturerId;
                manufacturerIdToSend = 0;
            }
        }

        //decide whether a known or new asset type
        let assetTypeNameToSend;
        let assetTypeIdToSend;
        if (selectedAssetTypeId === selectedAssetTypeName) {
            assetTypeNameToSend = selectedAssetTypeName;
            assetTypeIdToSend = 0;
            //selectionInAssetTypeList ? 
        } else {
            if (selectionInAssetTypeList) {
                assetTypeNameToSend = "" ;
                assetTypeIdToSend = selectedAssetTypeId;
            } else {
                assetTypeNameToSend = selectedAssetTypeId;
                assetTypeIdToSend = 0;
            }
        }


        const AssetInformationArray = [
            {attributeName: "name", attributeValue: formData.name},
            //{attributeName: "workstation_id", attributeValue: "TEST"},
            {attributeName: "manufacturer_id", attributeValue: manufacturerIdToSend},  //formData.manufacturer
            {attributeName: "manufacturer_name", attributeValue: manufacturerNameToSend},
            {attributeName: "type_id", attributeValue: assetTypeIdToSend},  
            {attributeName: "type_name", attributeValue: assetTypeNameToSend}, 
            {attributeName: "equipment_number", attributeValue: formData.eq_no},
            {attributeName: "workstation_id", attributeValue: workstation_id},
        ]
        return AssetInformationArray
    }

    //New Function for sending attributes and picture
    async function sendNewMessage2(auth, path, attributesArray, imageFile) {
        // form data
        let formData = new FormData();

         // append picture
         formData.append('image', imageFile);
         console.log(fileList[0].originFileObj)
    
        // json data
        let msgFields = {};
        attributesArray.forEach((attribute) => {
            const { attributeName, attributeValue } = attribute;
            msgFields[attributeName] = attributeValue;
        });
    
        formData.append('json', JSON.stringify(msgFields));
    
       
    
        // send message
        return fetch(hostName + path, {
            headers: {
                "Authorization": 'Bearer ' + auth,
                //"Content-Type": "multipart/form-data"
            },
            method: "POST",
            body: formData,
        })
        .then(function (response) {
            console.log(response);
            console.log("Response Status:", response.status);
            return response.status;
        })
    }

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    }

     //Show Ticket Create Alert
     const [api, contextHolder] = notification.useNotification();
     const openNotification = (placement, status, desc, title, errorCodeString) => {
         api[status]({
           message: title,
           description:
             <div>
                 <div>
                     {desc}
                 </div>
                 <div>
                     {errorCodeString}
                 </div>
             </div>
         });
     };

    function showCreateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response == 200) {
            title = t('assets.forms.create.asset.create-message.title.success');
            desc = t('assets.forms.create.asset.create-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            //console.log ('Error')^
            title = t('assets.forms.create.asset.create-message.title.error');
            desc = t('assets.forms.create.asset.create-message.body.error');
            errorCode = '[' + t('assets.forms.create.asset.create-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    async function handleAssetCreation(auth, attributesArray) {
        try {
            const result = await createNewTicket(auth, "/assets", attributesArray);
            showCreateAlert(result);
            if (result === 200) {callbackFct();}
        } catch (error) {
            console.error(error);
            //showAlert(error.status)
        }
    }

    const onFinish = (values) => {
    }
     
    const handleCancel = () => {
        setIsModalOpen(false)
    };

    useEffect(() => {
        setIsModalOpen(isOpen);
        loadManufacturers();
        // eslint-disable-next-line
    }, [isOpen])

    useEffect(() => {
    }, [selectedImage])


    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        //console.log(fileList[0].originFileObj)
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };  

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
      };

      const checkManu = () => {
        if (selectedManufacturerId !== "") {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t('assets.forms.create.asset.labels.manufacturer') + ' ' + t('assets.forms.error-message')));
      };

      const checkAssetType = () => {
        if (selectedAssetTypeId !== "") {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t('assets.forms.create.asset.labels.type') + ' ' + t('assets.forms.error-message')));
      };

    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={<>{t('assets.forms.create.asset.title')}{" "}<span className="ant-form-text" style={{opacity: 0.4}}>{"| "}{workstation_name}</span></>}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    autoComplete="off"
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                         label={t('assets.forms.create.asset.labels.Name')}
                         name="name"
                         rules={[{
                             required: true,
                             message: t('assets.forms.create.asset.labels.Name') + ' ' + t('assets.forms.error-message')
                         }]} 
                    >
                        <Input placeholder={t('assets.forms.create.asset.placeholder.name')}/>
                    </Form.Item>
                    <Form.Item
                         label={t('assets.forms.create.asset.labels.manufacturer')}
                         name="manufacturer"
                         value={selectedManufacturerId}
                         rules={[
                            {
                                required: true,
                                validator: checkManu, 
                            }
                        ]} 
                    >
                        <ManufacturerDropdown manufacturerList={manufacturerList} callbackFctId={setSelectedManufacturerId} callbackFctName={setSelectedManufacturerName} />
                    </Form.Item>
                    <Form.Item
                         label={t('assets.forms.create.asset.labels.type')}
                         name="type"
                         value={selectedAssetTypeId}
                         rules={[{
                             required: true,
                             validator: checkAssetType
                         }]} 
                    >
                        <AssetTypeDropdown assetTypeList={assetTypeList} callbackFctId={setSelectedAssetTypeId} callbackFctName={setSelectedAssetTypeName}/>
                    </Form.Item>
                    <Form.Item
                         label={t('assets.forms.create.asset.labels.eq-no')}
                         name="eq_no"
                         rules={[{
                             required: true,
                             message: t('assets.forms.create.asset.labels.eq-no') + ' ' + t('assets.forms.error-message')
                         }]} 
                    >
                        <Input placeholder={t('assets.forms.create.asset.placeholder.eq-no')}/>
                    </Form.Item>
                    {/* <Form.Item
                        label={t('assets.forms.create.asset.labels.assetImg')}
                        name="assetImg"
                    >
                        <UploadAsset onImageSelected={setSelectedImage} />
                    </Form.Item> */}
                    {/* <Form.Item
                        label={t('assets.forms.create.asset.labels.assetImg')}
                        name="assetImgCrop"
                    >
                        <ImgCrop zoomSlider>
                            <Upload
                                //action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                                customRequest={dummyRequest}
                                //beforeUpload={() => false}
                            >
                                {fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </ImgCrop>
                    </Form.Item> */}
                </Form>
                
            </Modal>
        </>
    );
}

export default AddAssetModal;
