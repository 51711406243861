import { Form, Input, Select, Cascader } from 'antd';
import { CustomerServiceOutlined, CarOutlined, PhoneOutlined, LaptopOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { fetchApi } from '../../../utils/RequestBuilder';


//Testing
const cascaderOptions = [
    {
      value: 'Modul A',
      label: 'Modul A',
      children: [
        {
          value: 'Issue A1',
          label: 'Issue A1',
        },
        {
            value: 'Issue A2',
            label: 'Issue A2',
        },
        {
            value: 'Issue A3',
            label: 'Issue A3',
        },
      ],
    },
    {
      value: 'Modul B',
      label: 'Modul B',
      children: [
        {
          value: 'Issue B1',
          label: 'Issue B1',
        },
        {
            value: 'Issue B2',
            label: 'Issue B2',
        },
      ],
    },
  ];

  const testcases = {
    "onsite": [
      {
        "id": 100,
        "label": "Cooling water",
        "issues": [
          {
            "id": 101,
            "label": "Leakage"
          },
          {
            "id": 102,
            "label": "Quality"
          }
        ]
      },
      {
        "id": 200,
        "label": "Umrichter",
        "issues": [
          {
            "id": 201,
            "label": "Spannung zu hoch"
          },
          {
            "id": 202,
            "label": "Spannung zu niedrig"
          }
        ]
      }
    ],
    "remote": [
        {
          "id": 20,
          "label": "Cooling water",
          "issues": [
            {
              "id": 21,
              "label": "Leakage"
            },
            {
              "id": 22,
              "label": "Quality"
            }
          ]
        }
      ]

    }

  



function ServiceCreateForm ({form, possibleServices, ticketId, assetId, auth}) {

    const { TextArea } = Input;
    const { t } = useTranslation();
    const [serviceOptions, setServiceOptions] = useState([]);
    const [caseOptions, setCaseOptions] = useState([]);
    const [caseOptionsForService, setCaseOptionsForService] = useState([]);

    //Fetch Modules and Issues from Server
    async function loadCasesData() {
        try {
            const jsonData = await fetchApi(auth, "/assets/" + assetId + "/service-options")
            setCaseOptions(jsonData);
        } catch (error) {
            // Handle error
            console.log(error)
        }
    }

    

    const onFinish = (values) => {
        console.log("Create Form finished:", values)
    }

    const layout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const onCascaderChange = (value) => {
        console.log(value);
      };

    const handleServiceChange = (value) => {
        //setCaseOptionsForService(caseOptions.value);
        console.log(getModulesByService(value));
        setCaseOptionsForService(getModulesByService(value));
    }

    /* useEffect(() => {
        setCaseOptionsForService(getModulesByService(value));
    }, [caseOptions]) */

    const getModulesByService = (label) => {
        if (caseOptions[label]) {
            const element = caseOptions[label];
            const updatedElement = element.map(item => {
                const updatedIssues = item.issues.map(issue => ({
                    ...issue,
                    value: issue.id
                }));
                return {
                    ...item,
                    children: updatedIssues,
                    value: item.id
                };
            });
            updatedElement.sort((a, b) => a.label.localeCompare(b.label));
            return updatedElement;
        } else {
            return null; 
        }
    }

    const getServiceIcon = (type) => {
        let icon;
        switch (type) {
            case "onsite":
                icon = <CarOutlined />;
                break;
            case "remote":
                icon = <LaptopOutlined />;
                break;
            case "guided":
                icon = <CustomerServiceOutlined/>;
                break;
            case "hotline":
                icon = <PhoneOutlined />;
                break;
            default:
                icon = ""
        } return icon
    }

    const buildServiceOptions = (possibleServices) => {
        let options = []

        for (const [key, value] of Object.entries(possibleServices)) {
            options.push({
                value: key,
                label: (
                    <div style={{ display: "flex", alignItems: "center"}}>
                        {getServiceIcon(key)}{""} 
                        <span style={{marginLeft: "4px"}}>{key.charAt(0).toUpperCase() + key.slice(1)}</span> 
                    </div>
                ),
                disabled: !value
            });    
        }
        return options;
    }

    const onValuesChange = (changedValues, allValues) => {
        if ('serviceSelect' in changedValues) {
            form.setFieldsValue({
                caseSelect: undefined,
            });
        }
    };

    //(getServiceIcon(key)) + " " + key.charAt(0).toUpperCase() + key.slice(1),

    useEffect(() => {
        setServiceOptions(buildServiceOptions(possibleServices));
        console.log("Service Options Array: ",buildServiceOptions(possibleServices))
        loadCasesData();
    }, [possibleServices]);

    return (
        <Form
            {...layout}
            autoComplete="off"
            name="basic"
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            initialValues={{
                comment: "",
            }}
        > 
            <Form.Item
                label={t('tickets.details.services-area.forms.create.labels.type')}
                name="serviceSelect"
                rules={[{
                    required: true,
                    message: t('tickets.details.services-area.forms.create.labels.type') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                }]}
            >
                <Select
                    options={serviceOptions}
                    placeholder={t('tickets.details.services-area.forms.create.placeholder.type')}
                    onChange={handleServiceChange}
                    //size={"large"}
                />
            </Form.Item>
            <Form.Item
                label={t('tickets.details.services-area.forms.create.labels.case')}
                name="caseSelect"
                rules={[{
                    required: true,
                    message: t('tickets.details.services-area.forms.create.labels.case') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                }]}
            >
                <Cascader 
                    options={caseOptionsForService} 
                    onChange={onCascaderChange} 
                    placeholder={t('tickets.details.services-area.forms.create.placeholder.case')}
                />
            </Form.Item>
            <Form.Item
                label={t('tickets.details.services-area.forms.create.labels.comment')}
                name="comment"
            >
                <TextArea 
                    rows={3}
                    placeholder={t('tickets.details.services-area.forms.create.placeholder.comment')} />
            </Form.Item>
        </Form>
    )

}

export default ServiceCreateForm