import {
    red,
    volcano,
    gold,
    yellow,
    lime,
    green,
    cyan,
    blue,
    geekblue,
    purple,
    magenta,
    grey,
  } from '@ant-design/colors';


//Get matching date format fpr selected Language
export function getDateFormat(lng)  {
    switch(lng) {
      case 'en':
        //console.log('YYYY/MM/DD hh:mm:ss a');
        return 'YYYY/MM/DD hh:mm:ss a';
      case 'de':
        //console.log('DD.MM.YYYY HH:mm:ss')
        return 'DD.MM.YYYY HH:mm:ss';
      default:
        return 'YYYY/MM/DD hh:mm:ss a';
    }
  }


//Get Tag color fpr Priority Tag
export function getPrioTagColor(priority) {
    switch(priority) {
        case 'MINOR':
            return 'green';
        case 'MAJOR': 
            return 'orange';
        case 'CRITICAL':
            return 'red';
        default:
            return 'blue';
    }
}

//Get Avatar color based on first character of signature
export function getAvatarColor(signature) {
    let x = (signature || []).slice(0,1);
    //console.log(x);
    switch(x) {
        case 'A':
        case 'F': 
        case 'M':
            return "#ffd666";  //gold-4
        case 'Q':
        case 'Ü':
        case 'T':
            return '#ff7875'; //red-4
        case 'O':
        case 'S':
            return 'green';
        case 'L':
        case 'B':
        case 'Ä':
            return '#ffc069'; //orange-4
        case 'U':
        case 'C':
            return 'blue';
        case 'R':
        case 'P': 
        case 'X':
            return '#85a5ff'; //geekblue-4
        case 'V':
        case 'K':
            return 'volcano';
        case 'H':
        case 'N':
        case 'G':
            return 'cyan';
        case 'J':
        case 'W':
            return 'gold';
        case 'Z':
        case 'Ö':
            return 'magenta';
        case 'I':
        case 'D':
        case 'Y':
            return 'purple';
        

        default: return 'grey'
    }
}
    export function transformToSelectablesUtils(originalData) {
        const selectables = {};
      
        originalData.forEach((workstation) => {
          const workstationKey = workstation.position.toString().padStart(3, '0');
          const workstationName = workstation.name;
          const workstationInternalId = workstation.internal_identifier;
          const workstationId = workstation.id;
          const workstationPosition = workstation.position;
      
          selectables[workstationKey] = {
            type: "workstation",
            name: workstationName,
            internal_identifier: workstationInternalId,
            id: workstationId,
            position: workstationPosition,
          };
      
          if (workstation.assets) {
            workstation.assets.forEach((asset) => {
                const assetKey = `${workstationKey}.${asset.position.toString().padStart(1, '0')}`;
                const assetName = asset.name;
                const assetInternalId = asset.internal_identifier;
                const assetId = asset.id;
                const assetPosition = asset.position;
                const assetType = asset.type;
                const assetManufacturer = asset.manufacturer;
                const assetNo = asset.equipment_number;
      
                selectables[assetKey] = {
                    name: assetName,
                    internal_identifier: assetInternalId,
                    id: assetId,
                    position: assetPosition,
                    type: assetType,
                    manufacturer: assetManufacturer,
                    equipment_number: assetNo
                };
            });
          }
        });

        return selectables;
      }
    
