import {Avatar, Descriptions, Badge, Tag, Select, Form, Button, Input, notification} from "antd";
import { HomeOutlined, SelectOutlined, EditOutlined, SaveOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import { getDateFormat, getAvatarColor } from "../../../utils/utils";
import { getPrioTagColor } from "../../../utils/utils";
import { changeTicketInfo, changeTicketOrderNoInfo, fetchApi } from "../../../utils/RequestBuilder";
import DescriptionsItem from "antd/es/descriptions/Item";
import { GlobalContext } from "../../../utils/GlobalContext";


dayjs.extend(duration)
dayjs.extend(relativeTime)


function timeFromCreation(created_at, closed_at) {
    var a = dayjs()
    if (closed_at === "NULL") {
        return dayjs(created_at).from(a, true);
    } else {
        return dayjs(created_at).to(closed_at, true);
    }
} 


function getActiveTimeTagColor(status, created_at, closed_at) {
    if (status == 'CLOSED' && closed_at !== "NULL") {
        return 'grey'
    } else {
        return 'red'
    }
}


function TicketSidebarArea({ticket, auth, callbackFct, hasManufacturerRole}) {

    const [ form ] = Form.useForm();
    const [ ticketData, setTicketData ] = useState(ticket);
    const [ source, setSource] = useState();
    const [ users, setUsers ] = useState([]);
    const [ initialStatus, setInitialStatus ] = useState("");
    const [isAddingOrderNo, setIsAddingOrderNo] = useState(false);
    const [orderNoToUpdate, setorderNoToUpdate] = useState("");
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    const  notificationContext  = useContext(GlobalContext);

    const { Option } = Select;


    const getSourceIcon = (type) => {
        let icon;
        console.log(ticket);
        switch (type) {
            case "INTERNAL":
                hasManufacturerRole ? icon = <SelectOutlined /> : icon = <HomeOutlined />
                break;
            case "EXTERNAL":
                hasManufacturerRole ? icon = <HomeOutlined /> : icon = <SelectOutlined />
                break;
            default:
                icon = ""
        } return icon
    }

    const getSourceText = (source) => {
        let sourceText = "";
        switch (source) {
            case "INTERNAL":
                hasManufacturerRole ? sourceText = t('tickets.details.sider-area.source.external') : sourceText = t('tickets.details.sider-area.source.internal')
                break;
            case "EXTERNAL": 
                hasManufacturerRole ? sourceText = t('tickets.details.sider-area.source.internal') : sourceText = t('tickets.details.sider-area.source.external')
                break;
            default:
                sourceText = " - "
                break;
        } return sourceText
    }

    const getStatusBadgeStatus = (ticketStatus) => {
        switch (ticketStatus) {
            case 'OPEN':
                return 'warning';
            case 'IN_PROGRESS':
                return 'processing';
            case 'CLOSED':
                return 'success';
            default:
                return 'default'; // oder einen anderen Standardstatus
        }
    };

    const getStatusBadgeText = (ticketStatus) => {
        switch (ticketStatus) {
            case 'OPEN':
                return 'Open';
            case 'IN_PROGRESS':
                return 'In Progress';
            case 'CLOSED':
                return 'Closed';
            default:
                return 'default'; // oder einen anderen Standardstatus
        }
    };

    //Fetch users from Server
    async function loadUsers() {
        try {
            const jsonData = await fetchApi(auth, "/users", auth)
            setUsers(jsonData);
            console.log("Users", users)
        } catch (error) {
            // Handle error
        }
    }


    //Change ticketData onChange of Ticket
    useEffect(() => {
        setTicketData(ticket)
        setorderNoToUpdate(ticket.order_number)
        /* if (hasManufacturerRole === false) */ loadUsers();
        form.resetFields()
       /*  if (ticketData.source === "INTERNAL") {
            setSource("internal")
            console.log(source)
        } else if (ticketData.source === "EXTERNAL") {
            setSource("external")
        }     */
    }, [ticket])



    useEffect(() => {
        if ("event" in notificationContext) {
            switch (notificationContext.event) {
                case "ticket_updated":
                    if(notificationContext.relation_id === ticket.id) {
                        setTicketData(prevState => ({
                            ...prevState,
                            booked_time_hours: notificationContext.payload.booked_time_hours,
                            booked_time_mins: notificationContext.payload.booked_time_mins,
                        }))
                    }
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationContext])

    


    //Show Ticket Information Update Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };

    function showUpdateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response == 200) {
            title = t('tickets.details.sider-area.update-message.title.success');
            desc = t('tickets.details.sider-area.update-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('tickets.details.sider-area.update-message.title.error');
            desc = t('tickets.details.sider-area.update-message.body.error');
            errorCode = '[' + t('tickets.details.sider-area.update-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    //Update Ticket information in database
    async function updateTicketInfo(auth, path,  name, value, id) {
        if (auth.user !== undefined) {
            const attributesArray = [
                {attributeName: name, attributeValue: value}
            ]
            try {
                const result = await changeTicketInfo(auth, path, attributesArray, id);
                console.log('result:', result);
                showUpdateAlert(result);
                if (result === 200) {
                    callbackFct();
                }
            } catch (error) {
                console.error(error);
                //showAlert(error.status)
            }
        }
    }

    //Update Order Number information in database
    async function updateOrderNo(auth, name, value, id) {
        if (auth.user !== undefined) {
            const attributesArray = [
                {attributeName: name, attributeValue: value}
            ]
            try {
                const result = await changeTicketOrderNoInfo(auth, attributesArray, id);
                console.log('result:', result);
                showUpdateAlert(result);
                if (result === 200) {
                    callbackFct();
                    return result;
                }
            } catch (error) {
                console.error(error);
                //showAlert(error.status)
                return {error};
            }
        }
    }

    const handleAddButtonClick = () => {
        console.log('Add button clicked');
        console.log(ticketData);
        setIsAddingOrderNo(true);
    };
    
    const handleEditButtonClick = () => {
        console.log('Edit button clicked');
        console.log(ticketData);
        setIsAddingOrderNo(true);
    };

    async function handleSaveButtonClick()  {
        console.log('Save button clicked');
        console.log(ticketData);
        const result = await updateOrderNo(auth, "order_number", orderNoToUpdate, ticket.id)
        if (result === 200) {
            setorderNoToUpdate("");
            setIsAddingOrderNo(false);
        }
    };

    const handleCancelButtonClick = () => {
        console.log('Cancel button clicked');
        setIsAddingOrderNo(false)
        setorderNoToUpdate(ticket.order_number);
        console.log(ticketData);
    };
    
    return (
        <>
            {contextHolder}
            <div>
                <Form
                    //autoComplete="off"
                    form={form}
                    name="basic"
                    initialValues={{
                        status: ticket.status,
                        assigned_to: ticket.assigned_to,
                        priority: ticket.priority
                        
                    }}
                    >
                    <Descriptions bordered column={1}>
                        {hasManufacturerRole && (
                            <Descriptions.Item label={t('tickets.details.sider-area.customer')}>{ticketData.customer_name}</Descriptions.Item>
                        )} 
                        <Descriptions.Item label={t('tickets.details.sider-area.equipment')}>{ticketData.asset}</Descriptions.Item>                       
                        <Descriptions.Item label={t('tickets.details.sider-area.source.initiator')}>
                            {ticketData.created_by}<br/>{getSourceIcon(ticketData.source)}{' '}{getSourceText(ticketData.source)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('tickets.details.sider-area.responsible.customer')}>
                            {!hasManufacturerRole ? (
                                <Form.Item
                                    name="assigned_to"
                                    style={{
                                        marginBottom: 0
                                    }}
                                >
                                    <Select
                                        showSearch
                                        onChange={(value) => updateTicketInfo(auth, "/tickets/", "assigned_to", value, ticket.id) }
                                        placeholder={t('tickets.details.sider-area.responsible.search')}
                                        optionFilterProp="children"
                                        style={{
                                            width: 160,
                                        }}
                                        filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                                    >
                                        { 
                                            (users || []).map((user) => {
                                                return(
                                                    <Option key={user.id} value={user.id} searchterm={user.name}>
                                                        <Avatar style={{ backgroundColor: getAvatarColor(user.signature)}}>
                                                            {user.signature}
                                                        </Avatar>  {user.name}
                                                    </Option>);
                                            })
                                        }
                                        {/* <Avatar style={{ backgroundColor: getAvatarColor(ticketData.assigned_to_signature)}}>
                                            {ticketData.assigned_to_signature}
                                        </Avatar>  {ticketData.assigned_to} */}
                                    </Select>
                                </Form.Item>
                            ) : (
                                ticket.assigned_to
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('tickets.details.sider-area.responsible.manufacturer')}>
                        {hasManufacturerRole ? (
                                <Form.Item
                                    name="manufacturer_engineer"
                                    style={{
                                        marginBottom: 0
                                    }}
                                >
                                    <Select
                                        showSearch
                                        defaultValue={ticket.manufacturer_engineer_name}
                                        onChange={(value) => updateTicketInfo(auth, "/customers/tickets/", "manufacturer_engineer", value, ticket.id) }
                                        placeholder={t('tickets.details.sider-area.responsible.search')}
                                        optionFilterProp="children"
                                        style={{
                                            width: 160,
                                        }}
                                        filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                                    >
                                        { 
                                            (users || []).map((user) => {
                                                return(
                                                    <Option key={user.id} value={user.id} searchterm={user.name}>
                                                        <Avatar style={{ backgroundColor: getAvatarColor(user.signature)}}>
                                                            {user.signature}
                                                        </Avatar>  {user.name}
                                                    </Option>);
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            ) : (
                                ticket.manufacturer_engineer_name === "" ? (t('requests.overview.not-assigned')) : ticket.manufacturer_engineer_name
                            )}

                        </Descriptions.Item>
                        <Descriptions.Item label={t('tickets.details.sider-area.priority')}>
                            {!hasManufacturerRole ? (
                                <Form.Item
                                    name="priority"
                                    style={{
                                        marginBottom: 0
                                    }}
                                >
                                    <Select
                                        style={{
                                            width: 160,
                                        }}
                                        onChange={(value) => updateTicketInfo(auth, "/tickets/", "priority", value, ticket.id) }
                                        options={[
                                            {
                                                value: 'MINOR',
                                                label: <Tag color={getPrioTagColor('MINOR')}>MINOR</Tag>
                                            },
                                            {
                                                value: 'MAJOR',
                                                label: <Tag color={getPrioTagColor('MAJOR')}>MAJOR</Tag>
                                            },
                                            {
                                                value: 'CRITICAL',
                                                label: <Tag color={getPrioTagColor('CRITICAL')}>CRITICAL</Tag>
                                            }
                                        ]}
                                    >
                                        <Tag color={getPrioTagColor(ticketData.priority)}>{ticketData.priority}</Tag>
                                    </Select>
                                </Form.Item>
                            ) : (
                                <Tag color={getPrioTagColor(ticketData.priority)}>{ticketData.priority}</Tag>
                            )}
                        </Descriptions.Item>
                            
                        <Descriptions.Item label={t('tickets.details.sider-area.status')}>
                            {!hasManufacturerRole ? (
                                <Form.Item
                                    name="status"
                                    style={{
                                        marginBottom: 0
                                    }}
                                >
                                    <Select
                                        style={{
                                            width: 160,
                                        }}
                                        onChange={(value) => updateTicketInfo(auth, "/tickets/", "status", value, ticket.id) }
                                        options={[
                                            {
                                                value: 'OPEN',
                                                label: <Badge status="warning" text="Open" />
                                            },
                                            {
                                                value: 'IN_PROGRESS',
                                                label: <Badge status="processing" text="In Progress" />
                                            },
                                            {
                                                value: 'CLOSED',
                                                label: <Badge status="success" text="Closed" />
                                            }
                                        ]}
                                    >
                                    </Select>
                                </Form.Item>
                            ) : (
                                <Badge status={getStatusBadgeStatus(ticketData.status)} text={getStatusBadgeText(ticketData.status)} />
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('tickets.details.sider-area.created-at')}>{dayjs(ticketData.created_at).format(getDateFormat(i18n.language))}</Descriptions.Item>
                        <Descriptions.Item label={t('tickets.details.sider-area.updated-at')}>{dayjs(ticketData.updated_at).format(getDateFormat(i18n.language))}</Descriptions.Item>
                        {ticketData.closed_at !== "NULL" && (
                            <Descriptions.Item label={t('tickets.details.sider-area.closed-at')}>
                                {dayjs(ticketData.closed_at).format(getDateFormat(i18n.language))}
                            </Descriptions.Item>
                        )}
                        <Descriptions.Item label={t('tickets.details.sider-area.active-time')}>
                            <Tag color={getActiveTimeTagColor(ticketData.status, ticketData.created_at, ticketData.closed_at)}>{timeFromCreation(ticketData.created_at, ticketData.closed_at)}</Tag>
                        </Descriptions.Item>
                        <Descriptions.Item 
                            label={t('tickets.details.sider-area.booked-hours')}>
                            {ticketData.booked_time_hours < 1 && ticketData.booked_time_mins < 1 ? (
                               "-" 
                            ) : (
                                <>{ticketData.booked_time_hours} {t('tickets.details.sider-area.hours')} {ticketData.booked_time_mins} {t('tickets.details.sider-area.minutes')}</>
                            )} 
                        </Descriptions.Item>
                        <Descriptions.Item label={t('tickets.details.sider-area.order-number')}>
                            {isAddingOrderNo && hasManufacturerRole ? (
                                <Form.Item
                                    name="order_number"
                                    style={{
                                        marginBottom: 0
                                    }}
                                    
                                >
                                    <Input
                                        placeholder={t('tickets.details.sider-area.order-number-placeholder')}
                                        onChange={(e) => setorderNoToUpdate(e.target.value)}
                                        value={orderNoToUpdate}
                                    />
                                    <Button type="primary" onClick={handleSaveButtonClick} style={{marginTop: "8px", marginRight: "4px"}}>
                                        <SaveOutlined /> {t('tickets.details.main-area.save')}
                                    </Button>
                                    <Button onClick={handleCancelButtonClick} style={{marginTop: "8px"}}>
                                        <CloseOutlined /> {t('tickets.details.main-area.cancel')}
                                    </Button>
                                </Form.Item>
                            ) : (
                                <div>
                                    {ticketData.order_number === "" && hasManufacturerRole && (
                                        <Button type="primary" onClick={handleAddButtonClick}>
                                            <PlusOutlined /> {t('Add')}
                                        </Button>
                                    )}
                                </div>
                            )}
                                <div>
                                    {ticketData.order_number === "" && !hasManufacturerRole && (
                                        <span>-</span>
                                    )}
                                    {ticketData.order_number !== "" && !isAddingOrderNo && (
                                        <span>{ticketData.order_number}</span>
                                    )
                                    }
                                    {hasManufacturerRole && ticketData.order_number !== "" && !isAddingOrderNo && (
                                        <Button type="primary" style={{ marginLeft: '8px' }} onClick={handleEditButtonClick}>
                                            <EditOutlined /> {t('Edit')}
                                        </Button>
                                    )}
                                </div>
                            {/* {ticketData.order_number === "" ? (
                                <div>
                                    <Input
                                        value={ticketData.orderNumber}>
                                    </Input>
                                    <span>{ticketData.order_number}</span>
                                    <Button type="primary" style={{ marginLeft: '8px' }} onClick={handleEditButtonClick}><EditOutlined /></Button>
                                </div>
                            ) : (
                                <Button type="primary" onClick={handleAddButtonClick}> + {t('tickets.details.sider-area.add-order-number')}</Button>
                            )}
                            {ticketData.order_number !=="" && isAddingOrderNo && (
                                <Button type="primary" style={{ marginLeft: '8px' }} onClick={handleEditButtonClick}>
                                    {t('tickets.details.sider-area.edit')}
                                </Button>
                            )} */} 
                        </Descriptions.Item>

                    </Descriptions>
                </Form>
            </div>
        </>  
    );
}

export default TicketSidebarArea;
