import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, notification } from 'antd';
import {useEffect, useState} from "react";
import { changeTicketInfo } from '../../utils/RequestBuilder';
import { wait } from '@testing-library/user-event/dist/utils';
//import useAuth, { hasRole, userRoles } from "../../hooks/useAuth"

function EditAssetModal({isOpen, auth, id, name, type, manufacturer, equipment_number, callbackFct, setMenuItemKey, selectId}) {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm();

    /* const [names, setNames] = useState();
    useEffect(() => {
        setNames(name);
        // eslint-disable-next-line
    }, [name]) */

    

    const handleOk = () => {
        form.validateFields().then(() => {
            const formValues = form.getFieldsValue();
            const WsInformationArray = createWsArray(formValues);
            //console.log(ticketInformationArray)
            setMenuItemKey(selectId)
            handleWsCreation(auth, WsInformationArray)
            //console.log(formValues)
            
            form.resetFields();
        })
    };

    function createWsArray(formData) {
        const WsInformationArray = [
            {attributeName: "name", attributeValue: formData.name},
            //{attributeName: "workstation_id", attributeValue: "TEST"},
            {attributeName: "manufacturer", attributeValue: formData.manufacturer},
            {attributeName: "type", attributeValue: formData.type},
            {attributeName: "equipment_number", attributeValue: formData.eq_no},
        ]
        return WsInformationArray
    }

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    }

     //Show Ticket Create Alert
     const [api, contextHolder] = notification.useNotification();
     const openNotification = (placement, status, desc, title, errorCodeString) => {
         api[status]({
           message: title,
           description:
             <div>
                 <div>
                     {desc}
                 </div>
                 <div>
                     {errorCodeString}
                 </div>
             </div>
         });
     };

    function showCreateAlert(response) {
        console.log("RESPONSE:", response)
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response === 200) {
            title = t('assets.forms.edit.asset.update-message.title.success');
            desc = t('assets.forms.edit.asset.update-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            //console.log ('Error')^
            title = t('assets.forms.edit.asset.update-message.title.error');
            desc = t('assets.forms.edit.asset.update-message.body.error');
            errorCode = '[' + t('assets.forms.update.asset.update-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    async function handleWsCreation(auth, attributesArray) {
        try {
            const result = await changeTicketInfo(auth, "/assets/", attributesArray, id);
            console.log('result', result);
            showCreateAlert(result);
            if (result === 200) {
                setIsModalOpen(false);
                console.log("SelectId EditAssetModal ",selectId)
                setMenuItemKey(selectId);
                callbackFct();}
        } catch (error) {
            console.error(error);
            //showAlert(error.status)
        }
    }

    const onFinish = (values) => {
        console.log("New Workstation created:", values)
        form.resetFields();
    }
     
    const handleCancel = () => {
        setIsModalOpen(false)
        form.resetFields();
    };

    useEffect(() => {
        setIsModalOpen(isOpen);
        //console.log(name);
        //form.resetFields();
        if (name !== undefined) {
            form.setFieldsValue({
                name: name,
                manufacturer: manufacturer,
                type: type,
                eq_no: equipment_number
            });
        }
        // eslint-disable-next-line
    }, [isOpen, form])

    /* useEffect(() => {
        form.resetFields();
        console.log(name)
        // eslint-disable-next-line
    }, [name]) */

    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={t('assets.forms.edit.asset.title')}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                //afterOpenChange={form.resetFields()}
            >
                <Form
                    {...layout}
                    autoComplete="off"
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        name: name,
                        manufacturer: manufacturer,
                        type: type,
                        eq_no: equipment_number
                    }}
                >
                    <Form.Item
                        label={t('assets.forms.edit.asset.labels.name')}
                        name="name"
                        rules={[{
                            required: true,
                            message: t('assets.forms.edit.asset.labels.name') + ' ' + t('assets.forms.error-message')
                        }]} 
                    >
                        <Input  />
                    </Form.Item>
                    <Form.Item
                        label={t('assets.forms.edit.asset.labels.manufacturer')}
                        name="manufacturer"
                        rules={[{
                             required: true,
                             message: t('assets.forms.edit.asset.labels.manufacturer') + ' ' + t('assets.forms.error-message')
                        }]} 
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                         label={t('assets.forms.edit.asset.labels.type')}
                         name="type"
                         rules={[{
                             required: true,
                             message: t('assets.forms.edit.asset.labels.type') + ' ' + t('assets.forms.error-message')
                         }]} 
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                         label={t('assets.forms.edit.asset.labels.eq-no')}
                         name="eq_no"
                         rules={[{
                             required: true,
                             message: t('assets.forms.edit.asset.labels.eq-no') + ' ' + t('assets.forms.error-message')
                         }]} 
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default EditAssetModal;