import {Button, Input, Space} from "antd";
import {FilterOutlined, SearchOutlined} from "@ant-design/icons";

function SearchBar() {

    return (
        <div style={{display: "flex", justifyContent: "space-between", marginTop: "15px"}}>
            <div>
                <Space direction="vertical" size="middle">
                    <Space.Compact
                        style={{
                            width: '100%',
                        }}
                    >
                        <Input defaultValue="Article Number, Description, Electrical Cabinet Position" />
                        <Button type="primary"><SearchOutlined />Search</Button>
                    </Space.Compact>
                </Space>
            </div>
            <div>
                <Button type="primary"><FilterOutlined />Filter</Button>
            </div>
        </div>
    );
}

export default SearchBar;
