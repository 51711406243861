import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter, redirect,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import App from "./App";
import ErrorPage from "./ErrorPage";
import Assets, { loader as assetsLoader } from "./views/Assets/Assets";
import './i18n'
import Tickets, { loader as ticketsLoader } from "./views/Tickets/Tickets";
import TicketDetails, { loader as ticketDetailsLoader } from "./views/Tickets/TicketDetails";
import Test from "./views/Test";
import WebshopTemplate from "./views/Webshop/WebshopTemplate";
import Search from "./views/Webshop/Search";
import Manufacturers from "./views/Administration/Manufacturers";
//import Equipment from "./views/Administration/Equipment";
import ModuleDetails from "./views/Administration/ModuleDetails";
import Administration from './views/Administration/Adminstration';
//import Requests from "./views/requests/Requests";
import {AuthProvider} from "react-oidc-context";
import {WebStorageStateStore} from "oidc-client-ts";
import Redirect from "./views/Redirect";
import Requests from "./views/requests/Requests";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Redirect />
                // Initial solution from GithHub
                // But id does lead to an endless loading loop when accessing the home directory after Keycloak login
                // loader() {
                //     console.log("LOADING")
                //     return redirect("/tickets");
                // }
            },
            {
                path: "test",
                element: <Test />,
            },
            {
                path: "administration",
                element: <Administration />,
            },
            /* {
                path: "administration/manufacturers",
                element: <Manufacturers />,
            }, */
            /* {
                path: "administration/modules/:id",
                element: <ModuleDetails />,
            }, */
            {
                path: "assets",
                element: <Assets />,
                loader: assetsLoader,
            },
            {
                path: "requests",
                element: <Requests />,
                //loader: ticketsLoader,
            },
            {
                path: "tickets",
                element: <Tickets />,
                //loader: ticketsLoader,
            },
            {
                path: "tickets/:id",
                element: <TicketDetails />,
                loader: ticketDetailsLoader,
            },
            {
                path: "webshop",
                element: <WebshopTemplate />,
                children: [
                    {
                        path: "search",
                        element: <Search />,
                    },
                ]
            },
        ]
    }
]);

const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
}


const keycloakUrl =  process.env.REACT_APP_KEYCLOAK_URL
const keycloackClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID

const oidcConfig = {
    authority: keycloakUrl + "realms/master",
    redirect_uri: window.location.href,
    client_id: keycloackClientId,
    onSigninCallback: onSigninCallback,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

ReactDOM.createRoot(document.getElementById('root')).render(
    <>
        <AuthProvider {...oidcConfig}>
            <RouterProvider router={router} />
        </AuthProvider>
    </>
);
