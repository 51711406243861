import React, { createContext, useState, useEffect } from 'react';


const GlobalContext = createContext();

const websocketUrl =  process.env.REACT_APP_WEBSOCKET_URL

const GlobalContextProvider = ({ children }) => {
  const [ notificationContext, setNotificationContext ] = useState({});
  //const [ notificationList, setNotifcationList ] = useState(1);
  

  useEffect(() => {
    //console.log(websocketUrl)
    const socket = new WebSocket(websocketUrl);
    socket.onopen = function() {
        console.log('WS connected')
    }
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if ("event" in data) {
          setNotificationContext(data);
      }
      //console.log('WS Message: ', data)
    };
    return () => {
      //socket.close(); 
    };
  }, []);

  return (
    <GlobalContext.Provider value={notificationContext}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextProvider };