import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { EditOutlined, DeleteOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Tooltip, Space, Divider } from 'antd';
import {useState, useEffect} from "react";
import DocumentsArea from "../../utils/DocumentsArea";
import EditAssetModal from "./EditAssetModal";
import useAuth, { hasRole, userRoles } from "../../hooks/useAuth"

const AssetDetails = ({ selectId, name, auth, id, type, manufacturer, equipment_number, callbackFct, setMenuItemKey }) => {

    
    const { t } = useTranslation();
    

    const [ editAssetModalIsOpen, setEditAssetModalIsOpen ] = useState(0);

    const asset = {
        id: id,
        equipmentNumber: equipment_number,
        name: name,
        manufacturer: manufacturer,
        type: type
    }

    const openEditAssetModal = () => {
        setEditAssetModalIsOpen(editAssetModalIsOpen + 1)
        console.log(editAssetModalIsOpen)
    };

    useEffect(() => {
        console.log("SelectId AssetDetails", selectId);
        setMenuItemKey(selectId);
    }, [selectId])
    

    return (
        <div>
            <Space 
                style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px 8px 0 0",
                    padding: "0 10px 0 10px"}}
            >
                <h2>{asset.name} | <Button ghost type="primary" shape="circle" onClick={() => openEditAssetModal()}icon={<EditOutlined />}></Button> | <Tooltip title={t('assets.overview.delete-asset-tooltip')}><Button disabled ghost danger type="primary" shape="circle" icon={<DeleteOutlined />}></Button></Tooltip></h2>
            </Space>
            <div 
                style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "0 8px 8px 8px",
                    padding: "0 10px 10px 10px",
                    marginBottom: "16px",
                    maxWidth: "425px"}}
            >
                <img style={{maxWidth: "400px"}} alt="example" src="/img/assets/OCC.png"/>
                <table>
                    <tbody>
                        <tr>
                            <th align="right" style={{padding: "0 16px 0 16px"}}>{t('assets.details.manufacturer')}</th>
                            <td>{asset.manufacturer}</td>
                        </tr>
                        <tr>
                            <th align="right" style={{padding: "0 16px 0 16px"}}>{t('assets.details.type')}</th>
                            <td>{asset.type}</td>
                        </tr>
                        <tr>
                            <th align="right" style={{padding: "0 16px 0 16px"}}>{t('assets.details.equipment-number')}</th>
                            <td>{asset.equipmentNumber}</td>
                        </tr>
                        <tr>
                            <th align="right" style={{padding: "0 16px 0 16px"}}>{t('assets.details.tickets')}</th>
                            <td>
                                <Link to={`/tickets?asset=${asset.id}`}>
                                    <Button 
                                        type="primary"
                                        onClick={() => {}}
                                    >
                                        <LinkOutlined/>{t('assets.details.ticket-link')}
                                    </Button>
                                </Link>
                            </td>
                        </tr>
                            {/* <th>ID</th>
                            <td>{asset.id}</td> */}
                    </tbody>
                </table>
                
            </div>
            
            <div 
                style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    padding: "0 10px 10px 10px",
                    marginTop: "16px",
                    maxWidth: "425px"}}
            >
                <DocumentsArea
                    objectId={id}
                    auth={auth}
                    type={"asset"}
                />  
            </div>
            <EditAssetModal 
                isOpen={editAssetModalIsOpen} 
                auth={auth}
                id={asset.id} 
                name={asset.name} 
                type={type} 
                manufacturer={manufacturer} 
                equipment_number={equipment_number}
                callbackFct={callbackFct}
                setMenuItemKey={setMenuItemKey}
                selectId={selectId} 
            />
        </div>
    )
}

export default AssetDetails;