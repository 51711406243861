import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, notification, Select } from 'antd';
import {useEffect, useState} from "react";
import { changeTicketInfo, changeServiceInfo, payloadApi } from '../../../utils/RequestBuilder';
import { wait } from '@testing-library/user-event/dist/utils';
//import useAuth, { hasRole, userRoles } from "../../hooks/useAuth"

function AddAssetTypeModal({isOpen, auth, callbackFct, productLineSelection}) {
    const { t } = useTranslation();
    const [isAtModalOpen, setIsAtModalOpen] = useState(false)
    const [productLines, setProductLines] = useState([]);
    
    const [form] = Form.useForm()

    useEffect(() => {
        setProductLines(productLineSelection)
    }, [productLineSelection])

    const handleOk = () => {
        form.validateFields().then(() => {
            const formValues = form.getFieldsValue();
            console.log(formValues)
            //const PlInformationArray = createPlArray(formValues);
            //console.log(ticketInformationArray)
            //setMenuItemKey(selectId)
            handleUpdate(auth, {
                "name": formValues.name, 
                "product_line_id": formValues.product_line
            })
            //console.log(formValues)
            setIsAtModalOpen(false);
            form.resetFields();
        })
    };

    /* function createPlArray(formData) {
        const PlInformationArray = [
            {attributeName: "name", attributeValue: formData.name},
        ]
        return PlInformationArray
    } */

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    }

     //Show Ticket Create Alert
     const [api, contextHolder] = notification.useNotification();
     const openNotification = (placement, status, desc, title, errorCodeString) => {
         api[status]({
           message: title,
           description:
             <div>
                 <div>
                     {desc}
                 </div>
                 <div>
                     {errorCodeString}
                 </div>
             </div>
         });
     };

    function showCreateAlert(response) {
        console.log("RESPONSE:", response)
        let desc = "";
        let title = "";
        let errorCode = "";
        console.log(response)
        if (response.status === 200) {
            title = t('administration.asset-types.form.create.asset-type.create-message.title.success');
            desc = t('administration.asset-types.form.create.asset-type.create-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            //console.log ('Error')^
            title = t('administration.asset-types.form.create.asset-type.create-message.title.error');
            desc = t('administration.asset-types.form.create.asset-type.create-message.body.error');
            errorCode = '[' + t('administration.asset-types.form.create.asset-type.create-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    async function handleUpdate(auth, payload) {
        try {
            const result = await payloadApi(
                auth,
                process.env.REACT_APP_MANU_MANAGEMENT_URL + "/asset-types",
                "POST",
                payload
            );
            callbackFct()
            showCreateAlert(result);
            if (result === 200) {callbackFct();}
        } catch (error) {
            console.log(error)
        }
        
    }

 

    const onFinish = (values) => {
        console.log("Asset Type Created:", values)
        form.resetFields();
    }
     
    const handleCancel = () => {
        setIsAtModalOpen(false)
        console.log("isAtModalOpen", isAtModalOpen)
        form.resetFields();
    };

    useEffect(() => {
        console.log("IsAtOpen:", isAtModalOpen)
        setIsAtModalOpen(isOpen);
        
        //form.resetFields();
        
        // eslint-disable-next-line
    }, [isOpen, form])

    function onPlChanged () {
        console.log("PL changed")
    }


    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={t('administration.asset-types.form.create.asset-type.title')}
                open={isAtModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                //afterOpenChange={form.resetFields()}
            >
                <Form
                    {...layout}
                    autoComplete="off"
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        
                    }}
                >
                    <Form.Item
                        label={t('administration.asset-types.form.create.asset-type.labels.product-line')}
                        name="product_line"
                        rules={[{
                            required: true,
                            message: t('administration.asset-types.form.create.asset-type.labels.product-line') + ' ' + t('requests.forms.create.error-message')
                        }]}
                    >
                        <Select
                            options={productLines.map((item) => ({
                                label: item.name,
                                value: item.id,
                            }))}
                            onChange={onPlChanged}
                            placeholder={t('administration.asset-types.form.placeholder.product-line')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('assets.forms.edit.asset.labels.name')}
                        name="name"
                        rules={[{
                            required: true,
                            message: t('assets.forms.edit.asset.labels.name') + ' ' + t('assets.forms.error-message')
                        }]} 
                    >
                        <Input placeholder={t('administration.asset-types.form.placeholder.name')}  />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default AddAssetTypeModal;