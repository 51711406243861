import {useAuth} from "react-oidc-context";
import {useEffect} from "react";
import {USER_ROLE_MANUFACTURER_ENGINEER, USER_ROLE_SERVICE_MANAGER, userHasRole} from "../utils/userManagement";

function Redirect() {
    const auth = useAuth();

    useEffect(() => {
        if (auth.user !== undefined) {
            if (userHasRole(auth.user.profile, USER_ROLE_SERVICE_MANAGER)) {
                window.location.replace("/tickets");
            } else if (userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER)) {
                window.location.replace("/requests");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])
}

export default Redirect;
