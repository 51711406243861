import { useTranslation } from 'react-i18next';
import { Form, Modal, notification } from 'antd';
import {useEffect, useState} from "react";
import ServiceCreateForm from '../Forms/ServiceCreateForm';
import { createNewTicket } from '../../../utils/RequestBuilder';


function AddServiceModal({isOpen, auth, possibleServices, ticketId, assetId, callbackFct}) {

    const { t } = useTranslation();

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [addForm] = Form.useForm();


    const handleOk = () => {
        addForm.validateFields().then(() => {
            const formValues = addForm.getFieldsValue();
            console.log("Formdata:",formValues)
            const serviceInformationArray = createServiceArray(formValues);
            //console.log(ticketInformationArray)
            handleServiceCreation(auth, serviceInformationArray)
            setIsAddModalOpen(false)
            addForm.resetFields();
        })
    };


    //Show Service Create Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };
   function showCreateAlert(response) {
       console.log("RESPONSE:", response)
       let desc = "";
       let title = "";
       let errorCode = "";
       if (response == 200) {
           title = t('tickets.details.services-area.forms.create.create-message.title.success');
           desc = t('tickets.details.services-area.forms.create.create-message.body.success');
           openNotification('topRight', 'success', desc, title, errorCode)
       } else {
           //console.log ('Error')^
           title = t('tickets.details.services-area.forms.create.create-message.title.error');
           desc = t('tickets.details.services-area.forms.create.create-message.body.error');
           errorCode = '[' + t('tickets.details.services-area.forms.create.create-message.status-code') + ': ' + response + ']'
           openNotification('topRight', 'error', desc, title, errorCode)
       }
   }

   function createServiceArray(formData) {
    const commentValue = formData.comment || "";
    const issueValue = formData.caseSelect[1] || 0;
    const serviceInformationArray = [
        {attributeName: "module_id", attributeValue: formData.caseSelect[0]},
        {attributeName: "issue_id", attributeValue: issueValue},
        {attributeName: "type", attributeValue: formData.serviceSelect},
        {attributeName: "comment", attributeValue: commentValue},
    
    ]
    console.log("serviceInformationArray", serviceInformationArray)
    return serviceInformationArray
    }

    async function handleServiceCreation(auth, attributesArray) {
        try {
            const result = await createNewTicket(auth, "/tickets/" + ticketId + "/services", attributesArray);
            console.log('result', result);
            showCreateAlert(result);
            if (result === 200) {callbackFct();}
        } catch (error) {
            console.error(error);
            //showAlert(error.status)
        }
    }

    const handleCancel = () => {
        setIsAddModalOpen(false)
    };

    useEffect(() => {
        setIsAddModalOpen(isOpen);
        // eslint-disable-next-line
    }, [isOpen])

    useEffect(() => {
        console.log("Possible Services",possibleServices);
        // eslint-disable-next-line
    }, [possibleServices])

    

    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={t('tickets.details.services-area.forms.create.title')}
                open={isAddModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <ServiceCreateForm form={addForm} possibleServices={possibleServices} ticketId={ticketId} assetId={assetId} auth={auth} />
            </Modal>
        </>
    );


}
 export default AddServiceModal