import { useTranslation } from "react-i18next";
import { Timeline } from "antd";
import { AlertOutlined, PhoneOutlined, CheckCircleOutlined, CarOutlined, CustomerServiceOutlined, LaptopOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from 'react';
import { fetchApi } from "../../../utils/RequestBuilder";
import dayjs from "dayjs";

function TimelineArea ({ticket, auth}) {

    const { t, i18n } = useTranslation();

    const [serviceHistoryTimeline, setServiceHistoryTimeline] = useState([]);
    const [finalTimeline, setFinalTimeline] = useState ([]);


    //Fetch Service History from Server
    async function loadServicesData() {
        try {
            const jsonData = await fetchApi(auth, "/tickets/" + ticket.id + "/services")
            setServiceHistoryTimeline(jsonData);
            console.log("serviceHistoryTimeline:", serviceHistoryTimeline)
        } catch (error) {
            // Handle error
        } 
    }

    function getDateFormatWithoutTime(lng)  {
        switch(lng) {
          case 'en':
            //console.log('YYYY/MM/DD hh:mm:ss a');
            return 'YYYY/MM/DD';
          case 'de':
            //console.log('DD.MM.YYYY HH:mm:ss')
            return 'DD.MM.YYYY';
          default:
            return 'YYYY/MM/DD';
        }
      }

    const buildTimeline = () => {
        if (Array.isArray(serviceHistoryTimeline)) {

            const timelineCopy = [...serviceHistoryTimeline];
            const transformedTimeline = timelineCopy.map(item => {
                let dot = null;
                if (item.type ==="guided") {
                    dot = <CustomerServiceOutlined style={{ fontSize: '16px'}}/>
                } else if (item.type ==="hotline") {
                    dot = <PhoneOutlined style={{ fontSize: '16px'}}/>
                } else if (item.type ==="onsite") {
                    dot = <CarOutlined style={{ fontSize: '16px'}}/>
                } else if (item.type ==="remote") {
                    dot = <LaptopOutlined style={{ fontSize: '16px'}}/>
                }

                return {
                    label: dayjs(item.created_at).format(getDateFormatWithoutTime(i18n.language)),
                    children: t('tickets.details.timeline-area.services.' + item.type) + ' ' + t('tickets.details.timeline-area.services.message'),
                    dot
                }
            });
            transformedTimeline.reverse();
            return transformedTimeline;
        } else {
            return [];
        }
    }


    useEffect(() => {
        if (ticket.hasOwnProperty('id')) {
            loadServicesData();
        }
        // eslint-disable-next-line
    }, [ticket])

    useEffect(() => {
        let timelineItems= [];
        if (serviceHistoryTimeline) {
            timelineItems = buildTimeline();
            console.log(timelineItems);
            timelineItems.unshift({
                label: dayjs(ticket.created_at).format(getDateFormatWithoutTime(i18n.language)),
                children: t('tickets.details.timeline-area.ticket-created'),
                dot: <AlertOutlined style={{ fontSize: '16px'}}/>,
                color: '#F9AC14'
            })
            if (ticket.closed_at !== "NULL") {
                timelineItems.push({
                    label: dayjs(ticket.closed_at).format(getDateFormatWithoutTime(i18n.language)),
                    children: t('tickets.details.timeline-area.ticket-closed'),
                    dot: <CheckCircleOutlined style={{ fontSize: '16px'}}/>,
                    color: 'green'
                })
            }
        }
        setFinalTimeline(timelineItems);
    }, [serviceHistoryTimeline])



    return (
        <Timeline
            mode="left"
            style={{paddingRight: "8px"}}
            items={finalTimeline}
        />
    )

}

export default TimelineArea