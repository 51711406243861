import { Button, List, Row, Col, Badge} from "antd";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation, } from "react-i18next";
import { PlusOutlined, CustomerServiceOutlined, CarOutlined, PhoneOutlined, LaptopOutlined, EditOutlined} from "@ant-design/icons";
import { fetchApi } from "../../../utils/RequestBuilder";
import AddServiceModal from "./AddServiceModal";
import { getDateFormat } from '../../../utils/utils';
import EditServiceModal from "./EditServiceModal";


const data = [
    {
        "comment": "",
        "created_at": "2023-08-08 14:00:00",
        "initiator": "Bernd Kunde",
        "manufacturer_engineer": "Matthias Klitz",
        "module": "Cooling Water",
        "issue": "Leackage",
        "status": "done",
        "type": "onsite"
      },
      {
        "comment": "Ganz viel ge trouble shooted",
        "created_at": "2023-10-12 14:00:00",
        "initiator": "Klemens Kunde",
        "manufacturer_engineer": "Jörg Lenze",
        "module": "Coil",
        "issue": "Temperature",
        "status": "done",
        "type": "remote"
      },
      {
        "comment": "Ganz viel ge trouble shooted",
        "created_at": "2023-10-12 14:00:00",
        "initiator": "Klemens Kunde",
        "manufacturer_engineer": "Jörg Lenze",
        "module": "Coil",
        "issue": "Temperature",
        "status": "in_progress",
        "type": "remote"
      },
      {
        "comment": "Ganz viel ge trouble shooted",
        "created_at": "2023-10-12 14:00:00",
        "initiator": "Klemens Kunde",
        "manufacturer_engineer": "Jörg Lenze",
        "module": "Coil",
        "issue": "Temperature",
        "status": "open",
        "type": "remote"
      }
]



function ServicesArea({ticket, auth, hasManufacturerRole}) {

    const { t, i18n } = useTranslation();

    const [shouldRenderOpenList, setShouldRenderOpenList] = useState(false);
    const [shouldRenderHistory, setShouldRenderHistory] = useState(false);
    const [addServiceModalIsOpen, setServiceModalIsOpen] = useState(0);
    const [editServiceModalIsOpen, setEditServiceModalIsOpen] = useState(0);
    const [serviceHistory, setServiceHistory] = useState([]);
    const [statusToEdit, setStatusToEdit] = useState();
    const [typeToEdit, setTypeToEdit] = useState();
    const [dateToEdit, setDateToEdit]= useState();
    const [moduleToEdit, setModuleToEdit] = useState();
    const [issueToEdit, setIssueToEdit] = useState();
    const [initiatorToEdit, setInitiatorToEdit] = useState();
    const [serviceEngineerToEdit, setServiceEngineerToEdit] = useState();
    const [commentToEdit, setCommentToEdit] = useState();
    const [idToEdit, setIdToEdit] = useState();



    //Fetch Service History from Server
    async function loadServicesData() {
            try {
                const jsonData = await fetchApi(auth, "/tickets/" + ticket.id + "/services")
                setServiceHistory(jsonData);
            } catch (error) {
                // Handle error
            } 
    }
    

    useEffect (() => {
        if (ticket.hasOwnProperty('id')) {
            loadServicesData();
            console.log("ticket", ticket)
        }
    }, [ticket])

    useEffect(() => {
        setShouldRenderOpenList(serviceHistory.some(item => item.status === 'open' || item.status === 'in_progress'));
        setShouldRenderHistory(serviceHistory.some(item => item.status === 'done'));
    }, [serviceHistory])

    const getServiceIcon = (type) => {
        let icon;
        switch (type) {
            case "onsite":
                icon = <CarOutlined />;
                break;
            case "remote":
                icon = <LaptopOutlined />;
                break;
            case "guided":
                icon = <CustomerServiceOutlined/>;
                break;
            case "hotline":
                icon = <PhoneOutlined />;
                break;
            default:
                icon = ""
        } return icon
    }

    const getServiceType = (type) => {
        let text;
        switch (type) {
            case "onsite":
                text = t('tickets.details.services-area.type-onsite');
                break;
            case "remote":
                text = t('tickets.details.services-area.type-remote');
                break;
            case "guided":
                text = t('tickets.details.services-area.type-guided');
                break;
            case "hotline":
                text = t('tickets.details.services-area.type-hotline');
                break;
            default:
                text = ""
        } return text
    }
    
    const getServiceStatus = (status) => {
        let badge;
        switch (status) {
            case "open":
              badge = <Badge  status="warning" text= {t('tickets.details.services-area.status-requested')} />;
              break;
            case "in_progress":
                badge = <Badge  status="processing" text= {t('tickets.details.services-area.status-progress')} />;
                break; 
            case "done":
                badge = <Badge  status="success" text= {t('tickets.details.services-area.status-done')} />;
                break;
            default: 
                badge = "";
        } return badge
    }

    const openServiceModal = () => {
        setServiceModalIsOpen(addServiceModalIsOpen + 1)
    };

    const handleEditClick = (status, type, created_at, module, issue, initiator, service_engineer, comment, id) =>{
        //console.log("EditClick: ", status, type, created_at, module, issue, initiator, service_engineer, comment);
        setStatusToEdit(status);
        setTypeToEdit(type);
        setDateToEdit(created_at);
        setModuleToEdit(module);
        setIssueToEdit(issue);
        setInitiatorToEdit(initiator);
        setServiceEngineerToEdit(service_engineer);
        setCommentToEdit(comment);
        setIdToEdit(id);
        setEditServiceModalIsOpen(editServiceModalIsOpen + 1);
    }

    return (
        <div>
            { !hasManufacturerRole && (
                <div style={{ textAlign: 'right' }}>
                    <Button
                        onClick={() => openServiceModal()}
                        type="primary">
                        <PlusOutlined 
                    />
                            {t('tickets.details.services-area.request')}
                    </Button>
                </div>
            )}
            <div
                style={{marginBottom: "0px"}}
            >
                {shouldRenderOpenList && ( <List
                    className="open-services-list"
                    style={{marginTop: "24px"}}
                    header={<b>{t('tickets.details.services-area.list-open')}</b>}
                    bordered
                    dataSource={serviceHistory}
                    renderItem={(item) => (
                        (item.status === "open" || item.status === "in_progress") && (
                        <List.Item>
                            {/* <List.Item.Meta
                                title={item.date}
                                description={item.contact}
                            /> */}
                            <div style={{width: "100%"}}>
                                <Row style={{width: "100%"}}>
                                    <Col span={8}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.status')}</th>
                                                    <td>{getServiceStatus(item.status)}</td>
                                                </tr>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.type')}</th>
                                                    <td>{getServiceIcon(item.type)}{" "}{getServiceType(item.type)}</td>
                                                </tr>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.date')}</th>
                                                    <td>{dayjs(item.created_at).format(getDateFormat(i18n.language))}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col span={8}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.module')}</th>
                                                    <td>{item.module}</td>
                                                </tr>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.issue')}</th>
                                                    <td>{item.issue}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.comment')}</th>
                                                    <td>{item.comment}</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col span={7}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.initiator')}</th>
                                                    <td>{item.initiator}</td>
                                                </tr>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.manufacturer-engineer')}</th>
                                                    <td>{item.manufacturer_engineer}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col span={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                        {hasManufacturerRole && (
                                            <table>
                                                <tbody>
                                                    <tr>    
                                                        <td>
                                                            <a href="#">
                                                                <EditOutlined
                                                                    style={{fontSize: "1vw"}} 
                                                                    onClick={() => handleEditClick(
                                                                        item.status,
                                                                        item.type,
                                                                        item.created_at,
                                                                        item.module,
                                                                        item.issue,
                                                                        item.initiator,
                                                                        item.manufacturer_engineer,
                                                                        item.comment,
                                                                        item.id
                                                                    )}  
                                                                />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </List.Item>
                        )
                    )}
                />
                )}
               { shouldRenderHistory && (<List
                    header={<b>{t('tickets.details.services-area.list-closed')}</b>}
                    bordered
                    style={{marginTop: "24px"}}
                    dataSource={serviceHistory}
                    renderItem={(item) => (
                        (item.status === "done") && (
                        <List.Item>
                            {/* <List.Item.Meta
                                title={item.date}
                                description={item.contact}
                            /> */}
                            <div style={{width: "100%"}}>
                                <Row style={{width: "100%"}}>
                                    <Col span={8}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.status')}</th>
                                                    <td>{getServiceStatus(item.status)}</td>
                                                </tr>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.type')}</th>
                                                    <td>{getServiceIcon(item.type)}{" "}{getServiceType(item.type)}</td>
                                                </tr>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.date')}</th>
                                                    <td>{dayjs(item.created_at).format(getDateFormat(i18n.language))}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col span={8}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.module')}</th>
                                                    <td>{item.module}</td>
                                                </tr>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.issue')}</th>
                                                    <td>{item.issue}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.comment')}</th>
                                                    <td>{item.comment}</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col span={8}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.initiator')}</th>
                                                    <td>{item.initiator}</td>
                                                </tr>
                                                <tr>
                                                    <th align="right" style={{padding: "0 16px 0 16px"}}>{t('tickets.details.services-area.manufacturer-engineer')}</th>
                                                    <td>{item.manufacturer_engineer}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </div>
                        </List.Item>
                        )
                    )}
                />
                )}
            </div>
            <AddServiceModal 
                isOpen={addServiceModalIsOpen} 
                auth={auth} 
                possibleServices={ticket.services} 
                ticketId={ticket.id} 
                assetId={ticket.asset_id} 
                callbackFct={loadServicesData}
            />
            <EditServiceModal  
                isOpen={editServiceModalIsOpen} 
                auth={auth}
                ticketId={ticket.id} 
                statusState={statusToEdit}
                typeState={typeToEdit}
                dateState={dateToEdit}
                moduleState={moduleToEdit}
                issueState={issueToEdit}
                initiatorState={initiatorToEdit}
                serviceEngineerState={serviceEngineerToEdit}
                commentState={commentToEdit}
                idState={idToEdit}
                callbackFct={loadServicesData}
            />
        </div>
    );
}

export default ServicesArea;
