import {Button, Select, Space} from "antd";
import { EditOutlined } from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getApi, payloadApi} from "../../../utils/RequestBuilder";

function AssignmentForm ({assetTypeId, serviceType, modules, issues}) {
    const { t } = useTranslation();

    const [formattedIssues, setFormattedIssues] = useState([])
    const [formattedModules, setFormattedModules] = useState([])
    const [selectedIssues, setSelectedIssues] = useState([])
    const [selectedModuleForIssue, setSelectedModuleForIssue] = useState(0)
    const [selectedModules, setSelectedModules] = useState([])
    const [modulesForIssue, setModulesForIssues] = useState([])

    async function loadAssignedModules(auth) {
        if (assetTypeId > 0) {
            try {
                const result = await getApi(auth,
                    process.env.REACT_APP_MANU_MANAGEMENT_URL + "/assign/asset-types/" +assetTypeId + "/service/" + serviceType);
                let resultModules = result.modules.split(",").map(Number)
                if (resultModules.length > 0 && resultModules[0] !== 0) {
                    setSelectedModules(resultModules)
                } else {
                    setSelectedModules([])
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    async function loadAssignedIssues(auth) {
        if (assetTypeId > 0) {
            try {
                const result = await getApi(auth,
                    process.env.REACT_APP_MANU_MANAGEMENT_URL +
                    "/assign/asset-types/" +assetTypeId + "/service/" + serviceType + "/modules/" + selectedModuleForIssue);
                let resultIssues = result.issues.split(",").map(Number)
                if (resultIssues.length > 0 && resultIssues[0] !== 0) {
                    setSelectedIssues(resultIssues)
                } else {
                    setSelectedIssues([])
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    async function handleUpdate(auth, payload, path) {
        try {
            const result = await payloadApi(
                auth,
                process.env.REACT_APP_MANU_MANAGEMENT_URL + path,
                "PUT",
                payload
            );
        } catch (error) {
            console.log(error)
        }
    }

    const handleIssuesChange = (value) => {
        setSelectedIssues(value)
    };

    const handleModulesChange = (value) => {
        setSelectedModules(value)
    };

    const handleModuleForIssuesChange = (value) => {
        setSelectedModuleForIssue(value)
    };

    function onUpdateIssues() {
        handleUpdate("", {
                issues: selectedIssues
            },
            "/assign/asset-types/" + assetTypeId + "/service/" + serviceType +"/modules/" + selectedModuleForIssue + "/bulk"
        )
    }

    function onUpdateModules() {
        handleUpdate("", {
            modules: selectedModules
        },
            "/assign/asset-types/" + assetTypeId + "/service/" + serviceType +"/bulk"
        )
    }

    useEffect(() => {
        let formatted = []
        issues.forEach((issue) => {
            formatted.push({
                key: issue.id,
                value: issue.id,
                label: issue.en,
            })
        })

        setFormattedIssues(formatted)
        // eslint-disable-next-line
    }, [issues])

    useEffect(() => {
        let formatted = []
        modules.forEach((module) => {
            formatted.push({
                key: module.id,
                value: module.id,
                label: module.en,
            })
        })

        setFormattedModules(formatted)
        // eslint-disable-next-line
    }, [modules])

    useEffect(() => {
        let optionsList = []
        modules.forEach((module) => {
            if (selectedModules.includes(module.id)) {
                optionsList.push({
                    label: module.en,
                    value: module.id
                })
            }
        })
        setModulesForIssues(optionsList)

        // eslint-disable-next-line
    }, [selectedModules]);

    useEffect(() => {
        loadAssignedModules()
        // eslint-disable-next-line
    }, [assetTypeId, serviceType])

    useEffect(() => {
        loadAssignedIssues()
        // eslint-disable-next-line
    }, [selectedModuleForIssue])

    return (
        <div>
            <Space direction="vertical" style={{marginTop: "16px"}}>
                <Space>
                    <b>{t('administration.asset-types.form.modules')}:</b>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            minWidth: 400,
                        }}
                        onChange={handleModulesChange}
                        options={formattedModules}
                        value={selectedModules}
                        placeholder={t('administration.asset-types.form.placeholder.modules')}
                    />
                    <Button
                        onClick={() => onUpdateModules()}
                    >
                        <EditOutlined />{t('administration.asset-types.form.edit.modules')}
                    </Button>
                </Space>
                <Space>
                    <p><b>{t('administration.asset-types.form.issues')}:</b></p>
                    <Select
                        style={{
                            minWidth: 160,
                        }}
                        onChange={handleModuleForIssuesChange}
                        options={modulesForIssue}
                        placeholder={t('administration.asset-types.form.placeholder.module')}
                    />
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            minWidth: 300,
                        }}
                        onChange={handleIssuesChange}
                        options={formattedIssues}
                        value={selectedIssues}
                        placeholder={t('administration.asset-types.form.placeholder.issues')}
                    />
                    <Button
                        onClick={() => onUpdateIssues()}
                        ><EditOutlined />{t('administration.asset-types.form.edit.issues')}
                    </Button>
                </Space>
            </Space>
        </div>
    )
}

export default AssignmentForm