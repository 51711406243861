import { Link, Outlet, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {useEffect, useState} from "react";
import { GlobalContextProvider } from "./utils/GlobalContext";
import { getAvatarColor } from "./utils/utils"
import './App.css'
import { Layout, Menu, Avatar } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined, UserOutlined, LogoutOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { userRoles } from "./hooks/useAuth";
import {hasAuthParams, useAuth} from "react-oidc-context";
import {USER_ROLE_MANUFACTURER_ENGINEER, USER_ROLE_SERVICE_MANAGER, USER_ROLE_ADMIN, userHasRole} from "./utils/userManagement";

const { Header, Content } = Layout;

function App() {
    const { t } = useTranslation();

    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);
    const [userSignature, setUserSignature] = useState("")
    const [userHasRoleServiceManager, setUserHasRoleServiceManager] = useState(false)
    const [userHasRoleManufacturerEngineer, setUserHasRoleManufacturerEngineer] = useState(false)
    const [userHasRoleAdmin, setUserHasRoleAdmin] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [selectedKey, setSelectedKey] = useState(['']);
    const location = useLocation();

    const accountMenuItems = [
        {
            label: <Avatar size= "large" style={{ backgroundColor: getAvatarColor(userSignature) }} >{userSignature}</Avatar>,
            children: [
                /* {
                    type: "group",
                    label: "Portal",
                    children: [
                        {
                            label: "Settings",
                            key: "settings",
                            icon: <SettingOutlined />
                        },
                        {
                            label: "Help",
                            key: "help",
                            icon: <QuestionCircleOutlined />
                        }
                    ]
                }, */
                {
                    type: "group",
                    label: "Account",
                    children: [
                        {
                            label: "Logout",
                            key: "logout",
                            icon: <LogoutOutlined />
                        }
                    ]
                }
            ]
            }
    ]

    const onAccountMenuClick = (event) => {
        if (event.key === "logout") {
            auth.removeUser()
            auth.signoutRedirect({
                id_token_hint: auth.user.id_token,
                post_logout_redirect_uri: window.location.href
            })
            setHasTriedSignin(false)
        }
    };

    // Load menu items based on the user's roles
    useEffect(() => {

        let newMenuItems = []

        if (userHasRoleServiceManager) {
            newMenuItems = [...newMenuItems,
                { label: <Link to={`/assets`}>{t('menu.assets')}</Link>,
                  key: 'assets',   
                },
                { label: <Link to={`/tickets`}>{t('menu.tickets')}</Link>,
                  key: 'tickets',
                },
            ]
        }
        if (userHasRoleManufacturerEngineer) {
            newMenuItems = [...newMenuItems,
                { label: <Link to={`/requests`}>{t('menu.requests')}</Link>,
                  key: 'requests',
                },
            ]
        }
        if (userHasRoleAdmin) {
            newMenuItems = [...newMenuItems,
                { label: <Link to={`/administration`}>{t('menu.admin')}</Link>,
                  key: 'administration', 
                },
             ]
        }
        setMenuItems(newMenuItems)

        // eslint-disable-next-line
    }, [userHasRoleServiceManager, userHasRoleManufacturerEngineer])

    // Silent login if a user re-visits the page and is still logged in
    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        } else if (auth.isAuthenticated) {
            // Set User Name signature
            setUserSignature(auth.user.profile.name.charAt(0) + auth.user.profile.family_name.charAt(0))
            setUserHasRoleServiceManager(userHasRole(auth.user.profile, USER_ROLE_SERVICE_MANAGER))
            setUserHasRoleManufacturerEngineer(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER))
            setUserHasRoleAdmin(userHasRole(auth.user.profile, USER_ROLE_ADMIN))
        }
    }, [auth, hasTriedSignin])

    //Keep selected menu key agfter refreshing
    useEffect(() => {
        const pathname = location.pathname;
        const key = getMenuKeyByPathname(pathname);
        setSelectedKey(key);
    }, [location]);

    const getMenuKeyByPathname = (pathname) => {
        switch (pathname) {
            case '/tickets':
                return ['tickets'];
            case '/administration':   
                return ['administration'];
            case '/requests':
                return ['requests'];
            case '/assets':
                return ['assets'];
            default:
                return [''];
        }
    };

    return (
        <GlobalContextProvider>
            { !auth.isLoading && auth.isAuthenticated &&
                <Layout className="layout">
                    <Header>
                        <div style={{display:"flex", justifyContent: "space-between"}}>
                            <div>
                                <div className="logo" />
                                <Menu
                                    theme="dark"
                                    mode="horizontal"
                                    defaultSelectedKeys={['assets']}
                                    selectedKeys={selectedKey}
                                    items={menuItems}
                                    style={{minWidth: "800px"}}
                                />
                            </div>
                            <Menu
                                  theme="dark"
                                  mode="horizontal"
                                  defaultSelectedKeys={['1']}
                                  items={accountMenuItems}
                                  onClick={onAccountMenuClick}
                                  //style={{marginBottom: "8px"}}
                            />
                            {/* <Avatar size= "large" style={{ backgroundColor: getAvatarColor("MR"),marginTop: "12px" }} >MR</Avatar> */}
                        </div>
                    </Header>
                    <Content
                        style={{
                            padding: '0 50px',
                        }}
                    >
                        <Outlet />
                    </Content>
                    <footer className="ant-layout-footer">
                        &copy; {new Date().getFullYear()} ABP Induction
                    </footer>
                </Layout>
            }
        </GlobalContextProvider>
    );
}

export default App;
