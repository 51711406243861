import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Form, Input, Modal} from "antd";
import {payloadApi} from "../../../utils/RequestBuilder";

function EditIssueModal({auth, callbackFct, isOpen, id, labels}) {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()

    const handleOk = () => {
        form.validateFields().then(() => {
            const formValues = form.getFieldsValue();
            handleUpdate(auth, createPayload(formValues))
            setIsModalOpen(false)
            form.resetFields();
        })
    };

    function createPayload(formData) {
        return {
            "en": formData.label_en,
            "de": formData.label_de,
            "es": formData.label_es,
            "pt": formData.label_pt,
            "fr": formData.label_fr,
            "ch": formData.label_ch,
        }
    }

    async function handleUpdate(auth, payload) {
        try {
            const result = await payloadApi(
                auth,
                process.env.REACT_APP_MANU_MANAGEMENT_URL + "/issues/" + id,
                "PUT",
                payload
            );
            callbackFct()
        } catch (error) {
            console.log(error)
        }
    }

    const onFinish = (values) => {
        form.resetFields();
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        form.resetFields();
    };

    useEffect(() => {
        setIsModalOpen(isOpen);
        form.setFieldsValue({
            label_en: labels["en"],
            label_de: labels["de"],
            label_es: labels["es"],
            label_pt: labels["pt"],
            label_fr: labels["fr"],
            label_ch: labels["ch"],
        })
        // eslint-disable-next-line
    }, [isOpen, form])


    return (
        <>
            <Modal
                title={t('administration.issues.form.update')}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    autoComplete="off"
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    initialValues={{
                        label_en: labels["en"],
                        label_de: labels["de"],
                        label_es: labels["es"],
                        label_pt: labels["pt"],
                        label_fr: labels["fr"],
                        label_ch: labels["ch"],
                    }}
                >
                    <Form.Item
                        label={t('administration.languages.en')}
                        name="label_en"
                        rules={[{
                            required: true,
                            message: t('administration.languages.en') + ' ' + t('assets.forms.error-message')
                        }]} 
                    >
                        <Input  />
                    </Form.Item>
                    <Form.Item
                        label={t('administration.languages.de')}
                        name="label_de"
                    >
                        <Input  />
                    </Form.Item>
                    <Form.Item
                        label={t('administration.languages.es')}
                        name="label_es"
                    >
                        <Input  />
                    </Form.Item>
                    <Form.Item
                        label={t('administration.languages.pt')}
                        name="label_pt"
                    >
                        <Input  />
                    </Form.Item>
                    <Form.Item
                        label={t('administration.languages.fr')}
                        name="label_fr"
                    >
                        <Input  />
                    </Form.Item>
                    <Form.Item
                        label={t('administration.languages.ch')}
                        name="label_ch"
                    >
                        <Input  />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default EditIssueModal