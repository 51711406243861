import React from 'react';
import { Card } from 'antd';
const { Meta } = Card;
const AssetCard = ({ selectId, name, setSelection, workstationName, position, internal_identifier, id, type, manufacturer, equipment_number, ticketCount}) => {
    const select = () => {
        console.log("AssetCard Select ID", selectId);
        setSelection(selectId, name, "asset", internal_identifier, id, position, type, manufacturer, equipment_number)
    }

    return (
        <Card
            hoverable
            style={{
                width: 240,
                marginTop: "8px",
                marginLeft: "16px",
                marginBottom: "8px"
            }}
            cover={<img alt="example" src="/img/assets/OCC.png"/>}
            onClick={() => select()}
        >
            <Meta
                title={name}
                description={"Tickets: " +  ticketCount}
            />
        </Card>
    )
}

export default AssetCard;