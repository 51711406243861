import {Button, Checkbox, Col, Input, Row, Tabs, Space, Form} from "antd";
import { EditOutlined } from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getApi, payloadApi} from "../../../utils/RequestBuilder";
import AssignmentForm from "./AssignmentForm";

function AssetTypeForm({id, modules, issues, callbackFunc}) {
    const { t } = useTranslation();

    const [nameForm] = Form.useForm()
    const [serviceForm] = Form.useForm()
    const [assetType, setAssetType] = useState({
        id: 0,
        name: "",
        service_guided_enabled: false,
        service_onsite_enabled: false,
        service_remote_enabled: false,
    })

    async function loadAssetType(auth) {
        if (id > 0) {
            try {
                const result = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/asset-types/" + id);
                setAssetType(result)
            } catch (error) {
                console.log(error)
            }
        }
    }

    async function handleUpdate(auth, payload) {
        try {
            const result = await payloadApi(
                auth,
                process.env.REACT_APP_MANU_MANAGEMENT_URL + "/asset-types/" + id,
                "PUT",
                payload
            );
            callbackFunc()
        } catch (error) {
            console.log(error)
        }
    }

    function onEditName() {
        let asset = assetType
        const formValues = nameForm.getFieldsValue();
        asset.name = formValues.name
        handleUpdate("",asset)
    }

    function onEditService() {
        let asset = assetType
        const formValues = serviceForm.getFieldsValue();
        asset.service_guided_enabled = formValues.guided
        asset.service_onsite_enabled = formValues.onsite
        asset.service_remote_enabled = formValues.remote
        handleUpdate("", asset)
    }

    useEffect(() => {
        nameForm.setFieldsValue({
            name: assetType.name,
        })
        serviceForm.setFieldsValue({
            guided: assetType.service_guided_enabled,
            onsite: assetType.service_onsite_enabled,
            remote: assetType.service_remote_enabled,
        })
        // eslint-disable-next-line
    }, [assetType])

    useEffect(() => {
        loadAssetType()
        // eslint-disable-next-line
    }, [id])

    return (
        <div>
            <h3 style={{marginTop: "8px"}}>{t('administration.asset-types.title')}</h3>
            <br />
            <Space direction="vertical" size="large">
                <Space size="middle">
                    <b>{t('administration.asset-types.form.name')}:</b>
                    <Form
                        autoComplete="off"
                        name="basic"
                        form={nameForm}
                    >
                        <Form.Item
                            name="name"
                            rules={[{
                                required: true,
                            }]}
                            style={{marginBottom: "0px"}}
                        >
                            <Input style={{minWidth: "350px"}} placeholder={t('administration.asset-types.form.placeholder.name')}/>
                        </Form.Item>
                    </Form>
                    <Button
                        type="primary"
                        onClick={() => onEditName()}
                    >
                        <EditOutlined/>{t('administration.asset-types.form.edit.name')}
                    </Button>
                </Space>
                <Space size="middle">
                    <b>{t('administration.asset-types.form.service-types')}:</b>
                    <div>
                        <Form
                            autoComplete="off"
                            name="basic"
                            form={serviceForm}
                        >
                            <Form.Item
                                name="guided"
                                valuePropName="checked"
                                style={{marginBottom: "0px"}}
                            >
                                <Checkbox>Guided Support</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="onsite"
                                valuePropName="checked"
                                style={{marginBottom: "0px"}}
                            >
                                <Checkbox>Onsite Visit</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="remote"
                                valuePropName="checked"
                                style={{marginBottom: "0px"}}
                            >
                                <Checkbox>Remote Service</Checkbox>
                            </Form.Item>
                        </Form>
                    </div>
                    <Button
                        type="primary"
                        onClick={() => onEditService()}
                    >
                        <EditOutlined/>{t('administration.asset-types.form.edit.services')}
                    </Button>
                </Space>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            key: '1',
                            label: 'Guided Service',
                            children: <AssignmentForm assetTypeId={id} serviceType={'guided'} modules={modules} issues={issues} />,
                            disabled: !assetType.service_guided_enabled,
                        },
                        {
                            key: '2',
                            label: 'Onsite Service',
                            children: <AssignmentForm assetTypeId={id} serviceType={'onsite'} modules={modules} issues={issues} />,
                            disabled: !assetType.service_onsite_enabled
                        },
                        {
                            key: '3',
                            label: 'Remote Service',
                            children: <AssignmentForm assetTypeId={id} serviceType={'remote'} modules={modules} issues={issues} />,
                            disabled: !assetType.service_remote_enabled
                        },
                    ]}
                    // onChange={onChange}
                />
            </Space>
        </div>
    );
}

export default AssetTypeForm