import { useTranslation } from 'react-i18next';
import {Button, Col, Row, Table, Tabs} from 'antd';
import React, { useState, useEffect, useContext, useRef } from 'react';
import AdminNavMenu from "../../components/Administration/AdminNavMenu";
import AssetTypesOverview from "../../components/Administration/AssetTypes/AssetTypesOverview";
import ModulesOverview from "../../components/Administration/Modules/ModulesOverview";
import IssuesOverview from "../../components/Administration/Issues/IssuesOverview";
import AssetTypesMenu from "../../components/Administration/AssetTypes/AssetTypesMenu";

function Administration() {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState("asset_types")

    useEffect(() => {
        if (window.location.hash !== "") {
            setActiveTab(window.location.hash.replace("#", ""))
        }

    }, [])

    function onChangeTab(activeKey) {
        setActiveTab(activeKey)
        window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#"+activeKey
    }


    return (
        <div>
            <h2>{t('administration.general.title')}</h2>
            <Tabs
                type='card'
                size='large'
                onChange={onChangeTab}
                activeKey={activeTab}
                items={
                    [
                        {
                            key: "asset_types",
                            label: t('administration.general.tabs.asset_types'),
                            children:
                                <div style={{marginTop: "10px"}}>
                                    <AssetTypesOverview />
                                </div>
                        },
                        {
                            key: "module",
                            label: t('administration.general.tabs.modules'),
                            children:
                                <div style={{marginTop: "10px"}}>
                                    <ModulesOverview />
                                </div>
                        },
                        {
                            key: "issues",
                            label: t('administration.general.tabs.issues'),
                            children:
                                <div style={{marginTop: "10px"}}>
                                    <IssuesOverview />
                                </div>
                        },
                    ]

                }
            />

        </div>
    );
}

export default Administration;
