import AssetCard from "./AssetCard";
import NoAssetCard from "./NoAssetCard";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, Space } from 'antd';
import { useState, useEffect} from "react";
import AddAssetModal from "./AddAssetModal";
import EditWorkstationModal from "./EditWorkstationModal";
import Scrollbars from "react-custom-scrollbars-2";
import { transformToSelectablesUtils } from "../../utils/utils";

const WorkstationDetails = ({ name, setSelection, auth, id, assets, callbackFct, setMenuItemKey, selectId}) => {

    const [assetList, setAssetList] = useState ([]);
    const [allAssets, setAllAssets] = useState ([]);

    const [ addAssetModalIsOpen, setAssetModalIsOpen ] = useState(0);
    const [ editWorkstationModalIsOpen, setEditWorkstationModalIsOpen ] = useState(0);

    const openAddAssetModal = () => {
        setAssetModalIsOpen(addAssetModalIsOpen + 1)
    };

    const openEditWorkstationModal = () => {
        setEditWorkstationModalIsOpen(editWorkstationModalIsOpen + 1)
    };

    useEffect(() => {
        const tempAssetList = assets;
        const wsAssets = findWsAssets(tempAssetList, id);
        setAssetList(wsAssets);
        setAllAssets(assets);
        //console.log("Workstation Assets:", wsAssets)
        //console.log(id)
    }, [id, assets])

    const findWsAssets = (data, targetId) => {
        for (const element of data) {
            if (element.id === targetId && element.assets && element.assets.length > 0) {
                return element.assets.sort((a, b) => a.position - b.position); // Sorted Assets
            }
        }
    }

    const { t } = useTranslation();

    //setMenuItemKey(selectId);
    function findSelectIdForAsset( currentId, selectables) {
        for (const key in selectables) {
            if (selectables.hasOwnProperty(key)) {
                const value = selectables[key];
                if (value.id === currentId) {
                    // Gefundene ID
                    return key;
                }
            }
        }
        return null;

    }


    function AssetList({data, setSelection}) {
        
        if (data !== undefined && data.length > 0){
            return (
                <div 
                    style={{ display: "flex", flexWrap: 'wrap'}}>
                {data.map(asset => (
                    <AssetCard
                    selectId={findSelectIdForAsset(asset.id, transformToSelectablesUtils(allAssets))}
                    name={asset.name}
                    setSelection={setSelection}
                    workstationName={name}
                    internal_identifier={asset.internal_identifier}
                    id={asset.id}
                    position={asset.position}
                    type={asset.type}
                    manufacturer={asset.manufacturer}
                    equipment_number={asset.equipment_number}
                    ticketCount={asset.ticket_count}
                    key={asset.id}
                    />
                ))}
                </div>
            );
        } else return <NoAssetCard />
    }

    return (
        <div>
            <Space 
                style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px 8px 0 0",
                    padding: "0 10px 0 10px"}}>
                <h2>
                    {name} | <Button ghost type="primary" shape="circle" onClick={() => openEditWorkstationModal()} icon={<EditOutlined />}></Button> | <Button ghost type="primary" shape="circle" onClick={() => openAddAssetModal()} icon={<PlusOutlined />}></Button> | <Tooltip title={t('assets.overview.delete-ws-tooltip')}><Button disabled ghost danger type="primary" shape="circle" icon={<DeleteOutlined />}></Button></Tooltip>
                </h2>
            </Space>         
            <div 
                style={{ 
                    display: "flex",
                    paddingBottom: "12px",
                    paddingTop: "8px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "0 8px 8px 8px"
                }}
            >
                <Scrollbars
                    autohide="true"
                    style={{minHeight: "72vh"}}
                >
                    <AssetList data={assetList} setSelection={setSelection} />
                </Scrollbars>
            </div>
            
            <AddAssetModal isOpen={addAssetModalIsOpen} auth={auth} callbackFct={callbackFct} workstation_id={id} setMenuItemKey={setMenuItemKey} selectId={selectId} workstation_name={name}/>
            <EditWorkstationModal isOpen={editWorkstationModalIsOpen} auth={auth} callbackFct={callbackFct} id={id} name={name} setMenuItemKey={setMenuItemKey} selectId={selectId}/>
        </div>
    )
}

export default WorkstationDetails;