import { useTranslation } from 'react-i18next';
import { Form, Modal, notification } from 'antd';
import {useEffect, useState} from "react";
import ServiceEditForm from '../Forms/ServiceEditForm';
import { changeServiceInfo } from '../../../utils/RequestBuilder';



function EditServiceModal({
    isOpen, 
    auth,
    ticketId,
    statusState,
    typeState,
    dateState,
    moduleState,
    issueState,
    initiatorState,
    serviceEngineerState,
    commentState,
    idState,
    callbackFct}) {

    const { t } = useTranslation();

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editForm] = Form.useForm();

    useEffect(() => {
        setIsEditModalOpen(isOpen);
        // eslint-disable-next-line
    }, [isOpen])

    //Show Service Update Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };
   function showCreateAlert(response) {
       console.log("RESPONSE:", response)
       let desc = "";
       let title = "";
       let errorCode = "";
       if (response == 200) {
           title = t('tickets.details.services-area.forms.edit.edit-message.title.success');
           desc = t('tickets.details.services-area.forms.edit.edit-message.body.success');
           openNotification('topRight', 'success', desc, title, errorCode)
       } else {
           //console.log ('Error')^
           title = t('tickets.details.services-area.forms.edit.edit-message.title.error');
           desc = t('tickets.details.services-area.forms.edit.edit-message.body.error');
           errorCode = '[' + t('tickets.details.services-area.forms.edit.edit-message.status-code') + ': ' + response + ']'
           openNotification('topRight', 'error', desc, title, errorCode)
       }
   }

   const handleOk = () => {
    editForm.validateFields().then(() => {
        const formValues = editForm.getFieldsValue();
        console.log("Formdata:",formValues)
        const serviceInformationArray = editServiceArray(formValues);
        //console.log(ticketInformationArray)
        handleServiceEdit(auth, serviceInformationArray, ticketId, idState);
        //editForm.resetFields();
        setIsEditModalOpen(false)
    })   
    }

    async function handleServiceEdit(auth, attributesArray, ticketId, serviceId) {
        try {
            const result = await changeServiceInfo(auth, "/tickets/" + ticketId + "/services/" + serviceId , attributesArray);
            console.log('result', result);
            showCreateAlert(result);
            if (result === 200) {callbackFct();}
        } catch (error) {
            console.error(error);
            //showAlert(error.status)
        }
    }


    function editServiceArray(formData) {
        let statusString;
        switch (formData.statusSelect) {
            case 1:
                statusString = "open";
                break;
            case 2:
                statusString = "in_progress";
                break;
            case 3: 
                statusString = "done";
                break;
            default:
                statusString = "open";
                break;
        }
        const serviceInformationArray = [
            {attributeName: "status", attributeValue: statusString},
            {attributeName: "manufacturer_engineer", attributeValue: formData.manufacturerEngineerSelect},
        ]
        return serviceInformationArray;
    }

    const handleCancel = () => {
        editForm.resetFields();
        setIsEditModalOpen(false)
    };



    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={t('tickets.details.services-area.forms.edit.title')}
                open={isEditModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <ServiceEditForm 
                    editForm={editForm} 
                    ticketId={ticketId} 
                    auth={auth}
                    statusState={statusState}
                    typeState={typeState}
                    dateState={dateState}
                    moduleState={moduleState}
                    issueState={issueState}
                    initiatorState={initiatorState}
                    serviceEngineerState={serviceEngineerState}
                    commentState={commentState}
                    formIsOpen={isEditModalOpen} />
            </Modal>
        </>
    );


}
export default EditServiceModal