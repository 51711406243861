import { Form, Input, Select, Upload, Avatar, Space, Badge, Row, Col, Tag, Cascader } from 'antd';
import { PlusOutlined, CustomerServiceOutlined, CarOutlined, PhoneOutlined, LaptopOutlined} from "@ant-design/icons";
import { InboxOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { fetchApi } from '../../../utils/RequestBuilder';
import { getAvatarColor } from '../../../utils/utils';
import dayjs from "dayjs";
import { getDateFormat } from '../../../utils/utils';



function ServiceEditForm ({
    editForm, 
    ticketId, 
    auth,
    statusState,
    typeState,
    dateState,
    moduleState,
    issueState,
    initiatorState,
    serviceEngineerState,
    commentState,
    formIsOpen}) {

    const { TextArea } = Input;
    const { t, i18n } = useTranslation();
    const { Option } = Select;
    const [serviceEngineersList, setServiceEngineersList] = useState([])
    const [selectedStatus, setSelectedStatus] = useState(statusState);
    const [selectedEngineer, setSelectedEngineer] = useState(serviceEngineerState);
    const [statusOptions, setStatusOptions] = useState([])



    const getServiceStatus = (status) => {
        let badge;
        switch (status) {
            case "open":
              badge = <Badge  status="warning" text= {t('tickets.details.services-area.status-requested')} />;
              break;
            case "in_progress":
                badge = <Badge  status="processing" text= {t('tickets.details.services-area.status-progress')} />;
                break; 
            case "done":
                badge = <Badge  status="success" text= {t('tickets.details.services-area.status-done')} />;
                break;
            default: 
                badge = "";
        } return badge
    }

    const loadStatusOptions = () => {
        switch (getStatusValueByName(statusState)) {
            case 1:
                setStatusOptions(
                    [
                        {
                            value: 1,
                            key: 1,
                            label: <>{getServiceStatus("open")}</>
                        },
                        {
                            value: 2,
                            key: 2,
                            label: <>{getServiceStatus("in_progress")}</>
                        },
                        {
                            value: 3,
                            key: 3,
                            label: <>{getServiceStatus("done")}</>
                        }
                    ]
                )
                break;
            case 2:
                setStatusOptions(
                    [
                        {
                            value: 2,
                            key: 2,
                            label: <>{getServiceStatus("in_progress")}</>
                        },
                        {
                            value: 3,
                            key: 3,
                            label: <>{getServiceStatus("done")}</>
                        }
                    ]
                )
                break;
            default:
                setStatusOptions(
                    [
                        {
                            value: 1,
                            key: 1,
                            label: <>{getServiceStatus("open")}</>
                        },
                        {
                            value: 2,
                            key: 2,
                            label: <>{getServiceStatus("in_progress")}</>
                        },
                        {
                            value: 3,
                            key: 3,
                            label: <>{getServiceStatus("done")}</>
                        }
                    ]
                )
                break;
            
        }
    }

    const getStatusValueByName = (name) => {
        switch(name) {
            case "open":
                return 1;
            case "in_progress":
                return 2;
            case "done":
                return 3;
            default:
                return 0;
        }
    }
    


    //Fetch Service Engineers List from Server
    async function loadServiceEngineersList() {
        try {
            const jsonData = await fetchApi(auth, "/manufacturer-engineers")
            setServiceEngineersList(jsonData);
            console.log("ServiceEngineers:", jsonData)
        } catch (error) {
            // Handle error
        }
    }

    useEffect(() => {
        loadServiceEngineersList();
    }, [editForm])

    //Set initial Values for Select fields
    useEffect(() => {
        setSelectedStatus(getStatusValueByName(statusState));
        editForm.setFieldValue("statusSelect", getStatusValueByName(statusState));
        loadStatusOptions();
        //console.log("InitialStatus set to: ", initialStatus);
        //console.log("InitialEngineer set to: ", initialEngineer)
    }, [statusState])

    useEffect(() => {
        setSelectedEngineer(getEngineerIdByName(serviceEngineerState));
        editForm.setFieldValue("manufacturerEngineerSelect", getEngineerIdByName(serviceEngineerState));
        //console.log("InitialStatus set to: ", initialStatus);
        //console.log("InitialEngineer set to: ", initialEngineer)
    }, [serviceEngineerState, serviceEngineersList])

    useEffect(() => {
        console.log("formIsOpen",formIsOpen)
        if (formIsOpen >= 1) {
            console.log("triggered")
            setSelectedEngineer(getEngineerIdByName(serviceEngineerState));
            editForm.setFieldValue("manufacturerEngineerSelect", getEngineerIdByName(serviceEngineerState))
            setSelectedStatus(getStatusValueByName(statusState));
            editForm.setFieldValue("statusSelect", getStatusValueByName(statusState));
            loadStatusOptions();
        } 

    }, [formIsOpen])



    /* useEffect(() => {
        editForm.setFieldValue("manufacturerEngineerSelect2", getEngineerIdByName(selectedEngineer));
        console.log("slected Engineer useEffect");
    }, [selectedEngineer]) */


    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    const onFinish = (values) => {
        console.log("Edit Form finished:", values)
    }

    const getServiceType = (type) => {
        let text;
        switch (type) {
            case "onsite":
                text = t('tickets.details.services-area.type-onsite');
                break;
            case "remote":
                text = t('tickets.details.services-area.type-remote');
                break;
            case "guided":
                text = t('tickets.details.services-area.type-guided');
                break;
            case "hotline":
                text = t('tickets.details.services-area.type-hotline');
                break;
            default:
                text = ""
        } return text
    }
    const getServiceIcon = (type) => {
        let icon;
       
        switch (type) {
            case "onsite":
                icon = <CarOutlined />;
                break;
            case "remote":
                icon = <LaptopOutlined />;
                break;
            case "guided":
                icon = <CustomerServiceOutlined/>;
                break;
            case "hotline":
                icon = <PhoneOutlined />;
                break;
            default:
                icon = ""
        } return icon
    }
    

    const getEngineerIdByName = (name) => {
        const engineersList = [...serviceEngineersList];
        const engineer = engineersList.find ((engineer) => engineer.name === name);
        return (engineer ? engineer.id : undefined)
    }

    const onValuesChange = (changedValues, allValues) => {
        console.log(allValues);
    };

    const onStatusChange = (option) => {
        editForm.setFieldValue("statusSelect", option.value)
        setSelectedStatus(option.value)
    };

    const onEngineerChange = (option) => {
        editForm.setFieldValue("manufacturerEngineerSelect", option.value)
        setSelectedEngineer(option.value)
    }


  
    return (
        <Form
            {...layout}
            autoComplete="off"
            name="basic"
            form={editForm}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            initialValues={{
                //statusSelect: getStatusValueByName(statusState),
                //manufacturerEngineerSelect: getEngineerIdByName(serviceEngineerState),
                //dateDisplay:dateState 
            }} 
        >
            <Row gutter={16}>
                <Col span={9}>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.edit.labels.status')}
                        //name="statusSelect"
                        shouldUpdate
                        rules={[{
                            required: true,
                            message: t('tickets.details.services-area.forms.edit.labels.status') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                        }]}
                    >
                        {() => {
                            return (
                                <Select
                                    options={statusOptions}
                                    value={selectedStatus}
                                    //onChange={(label, option) => setSelectedStatus(option.value)}
                                    onChange={(label, option) => onStatusChange(option)}
                                />
                            )
                        }}
                    </Form.Item>
                    <Form.Item
                        //shouldUpdate
                        hidden="true"
                        name="statusSelect"
                    >
                        <Select
                            value={selectedStatus}
                            options={statusOptions}
                            
                        />
                    </Form.Item>
                    
                    <Form.Item
                        label={t('tickets.details.services-area.forms.edit.labels.manufacturer-engineer')}
                        name="manufacturerEngineerSelect"
                        //hidden="true"
                        rules={[{
                            required: true,
                            message: t('tickets.details.services-area.forms.edit.labels.manufacturer-engineer') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                        }]}
                    >
                        <Select
                            value={selectedEngineer}
                        >
                            { 
                                (serviceEngineersList || []).map((engineer) => {
                                    return(
                                        <Option key={engineer.id} value={engineer.id} searchterm={engineer.name}>
                                            <Avatar style={{ backgroundColor: getAvatarColor(engineer.signature)}}>
                                                {engineer.signature}
                                            </Avatar>  {engineer.name}
                                        </Option>);
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.edit.labels.type')}
                        name="typeDisplay" 
                    >
                        <span className="ant-form-text">{getServiceIcon(typeState)}{" "}{getServiceType(typeState)}</span>
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.edit.labels.date')}
                        name="dateDisplay" 
                    >
                        <span className="ant-form-text">{dayjs(dateState).format(getDateFormat(i18n.language))}</span>
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.edit.labels.module')}
                        name="moduleDisplay"
                    >
                         <span className="ant-form-text">{moduleState}</span>
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.edit.labels.issue')}
                        name="issueDisplay"
                    >
                         <span className="ant-form-text">{issueState}</span>
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.edit.labels.initiator')}
                        name="initiatorDisplay"
                    >
                         <span className="ant-form-text">{initiatorState}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.edit.labels.comment')}
                        name="initiatorDisplay"
                    >
                         <span className="ant-form-text">{commentState}</span>
                    </Form.Item>
                    
                </Col>
            </Row>
        </Form>
    )

}

export default ServiceEditForm