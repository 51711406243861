import {Link} from "react-router-dom";
import { useLoaderData } from "react-router-dom";
import CardTemplate from "../../components/Tickets/Details/CardTemplate";
import TicketSidebarArea from "../../components/Tickets/Details/TicketSidebarArea";
import MainAreaCard from "../../components/Tickets/Details/MainAreaCard";
import { useTranslation } from "react-i18next";
import { Layout, Col, Row, Tabs, Button, notification } from "antd";
import DocumentsArea from "../../utils/DocumentsArea";
import ChatArea from "../../components/Tickets/Details/ChatArea";
import TimeBookingArea from "../../components/Tickets/Details/TimeBookingArea";
import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from "../../utils/GlobalContext";
import { fetchApi } from "../../utils/RequestBuilder";
import ServicesArea from "../../components/Tickets/Details/ServicesArea";
import TimelineArea from "../../components/Tickets/Details/TicketTimelineArea";
import { useAuth } from 'react-oidc-context';
import { userHasRole, USER_ROLE_MANUFACTURER_ENGINEER } from "../../utils/userManagement";

const {Content, Sider} = Layout;

export async function loader({ params }) {
    return params.id;
}

function TicketDetails() {
    const ticketId = useLoaderData();
    const [ticket, setTicket] = useState([]);
    const [users, setUsers] = useState([])
    const [name, setName] = useState("")
    const [hasServiceManagerRole, setRole] = useState(false)
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const  notificationContext  = useContext(GlobalContext);
    const [activeTab, setActiveTab] = useState("chat")
    //const tabsRef = useRef(null);

    //Auth
    const auth = useAuth();
    const [user, setUser] = useState({sub: ""})

    //Fetch ticket from Server
    async function loadTicketData() {
        try {
            const jsonData = await fetchApi(auth, "/tickets/" + ticketId)
            setTicket(jsonData);
            //console.log("jsonData:", jsonData)
        } catch (error) {
            // Handle error
        }
    }

    /* const scrollToBottom = () => {
        console.log(tabsRef.current)
        if (tabsRef.current) {
            console.log("sollte Scrollen")
            tabsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end'});
        }
    } */

    //Show push notification
    const openPushNotification = (placement, status, desc, title, ticketId, msgOrigin) => {
        api[status]({
            duration: 6.5,
            message: title,
            description:
                <div>
                    <div>
                        <b>{msgOrigin}</b> : {desc}
                    </div>
                    <br/>
                    <div onClick={jumpToChat}>
                        <Link to={`/tickets/${ticketId}#chat`} >
                            {t('tickets.details.push.link')}
                        </Link>
                    </div>
                </div>
        });
    };
    function showPushNotification(msgString, ticketId, msgSender) {
        let desc = msgString.length > 50 ? `${msgString.substring(0, 50)}...` : msgString
        let title = t('tickets.details.push.title');
        let msgOrigin = msgSender;
        console.log(`/tickets/${ticketId}`);
        openPushNotification('topRight', 'info', desc, title, ticketId, msgOrigin)  
    }

    function jumpToChat() {
        if (activeTab !== "chat") {
            setActiveTab("chat")
        }
    }

    function onChangeTab(activeKey) {
        setActiveTab(activeKey)
        window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#"+activeKey
    }


    //Initial page call
    useEffect(() => {
        if (auth.user !== undefined) {
            //setUser(auth.user.profile)
            loadTicketData();
            //setName(user.name)
            setRole(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER))
        }
        // eslint-disable-next-line
    }, [auth])

    useEffect(() => {
        if ("event" in notificationContext) {
            switch (notificationContext.event) {
                case "message_created":
                    if ((notificationContext.payload.participant === "manufacturer" && !hasServiceManagerRole) || (notificationContext.payload.participant === "customer" && hasServiceManagerRole)) {
                        showPushNotification(
                            notificationContext.payload.text,
                            notificationContext.relation_id,
                            notificationContext.payload.title);
                    }
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationContext])

    useEffect(() => {
        if (window.location.hash !== "") {
            setActiveTab(window.location.hash.replace("#", ""))
        }

    }, [])

    return (
        <>
            {contextHolder}
            <Layout hasSider>
                <Sider
                    style={{
                        backgroundColor: '#f5f5f5',
                        overflow: 'auto',
                        height: '100vh',
                        position:'fixed',
                        right: '24px',
                        top: '88px',
                        bottom: 0,
                    }}
                    width = "360px"
                    theme= 'light'
                >
                    <TicketSidebarArea
                        ticket={ticket}
                        auth={auth}
                        callbackFct={loadTicketData}
                        hasManufacturerRole={hasServiceManagerRole}/> 
                </Sider>
                <Layout
                    className="site-layout"
                    style={{
                        marginRight:340,
                    }}
                >
                    <Content
                        style={{
                            margin: '24px 16px 0',
                            overflow: 'initial',
                        }}
                    >
                        <div>
                            <Row>
                                <Col span={6}>
                                    <div
                                        style={{
                                            backgroundColor: "#f5f5f5",
                                            borderRadius: "8px",
                                            padding: "0 10px 10px 10px",
                                            //marginTop: "4px",
                                            marginRight: "32px",
                                            paddingTop: "16px",
                                            //paddingRight: "8px"
                                        }}
                                    >
                                            <TimelineArea
                                                ticket={ticket} 
                                                auth={auth}
                                            />
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <div id="sub-content">
                                        <Row>
                                            <Col span={24}>
                                                <MainAreaCard
                                                    ticket={ticket}
                                                    auth={auth}
                                                    hasManufacturerRole={hasServiceManagerRole}
                                                /> 
                                            </Col>
                                        </Row>
                                        {/* <Button onClick={scrollToBottom}>Scroll To Bottom</Button> */}
                                        <Tabs
                                            //ref={tabsRef}
                                            // defaultActiveKey='1'
                                            type='card'
                                            size='large'
                                            //style={{height: "10vh"}}
                                            onChange={onChangeTab}
                                            activeKey={activeTab}
                                            items={[
                                                {
                                                    label: t('tickets.details.chat-area.header'),
                                                    key: 'chat',
                                                    children: 
                                                        <div>
                                                            <CardTemplate
                                                                content={
                                                                    <ChatArea
                                                                        id={ticketId}
                                                                        auth={auth}
                                                                        hasManufacturerRole={hasServiceManagerRole}
                                                                    />
                                                                }
                                                            />  
                                                        </div>
                                                },
                                                {
                                                    label: t('tickets.details.services-area.header'),
                                                    key: 'requests',
                                                    children: 
                                                        <div>
                                                            <CardTemplate
                                                                content={
                                                                    <ServicesArea
                                                                        ticket={ticket}
                                                                        auth={auth}
                                                                        hasManufacturerRole={hasServiceManagerRole}
                                                                    />
                                                                }
                                                            />  
                                                        </div>
                                                },
                                                {
                                                    label: t('tickets.details.documents-area.header'),
                                                    key: 'documents',
                                                    children: 
                                                        <div>
                                                            <DocumentsArea
                                                                objectId={ticketId}
                                                                auth={auth}
                                                                type={"ticket"}
                                                            />  
                                                        </div>
                                                },
                                                {
                                                    label: t('tickets.details.time-booking-area.header'),
                                                    key: 'time-booking',
                                                    children: 
                                                        <div>
                                                            <CardTemplate
                                                                content={
                                                                    <TimeBookingArea
                                                                        ticketId={ticket.id}
                                                                        auth={auth}
                                                                        hasManufacturerRole={hasServiceManagerRole}
                                                                    />
                                                                }
                                                            />  
                                                        </div>
                                                },
                                                    
                                            ]}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

export default TicketDetails;


