import React from 'react';
import { Card } from 'antd';
import ManufacturerDropdown from './ManufacturerDropdown';
import AssetTypeDropdown from './AssetTypeDropdown';
const { Meta } = Card;




const NoAssetCard = () => {

    return (
        <>
        <Card
            hoverable={false}
            style={{
                width: 240,
                marginTop: "8px",
                marginLeft: "16px",
                marginBottom: "8px"
            }}
            cover={<img alt="example" src="/img/assets/OCC.png"/>}
            //onClick={() => select()}
        >
            <Meta
                title={"No Assets"}
                //description={"Tickets: " +  workstationName}
            />
            
        </Card>
         
          
        </>
    )
}

export default NoAssetCard;