import { Card, Row, Col, Button, Input, Space, Form, notification } from 'antd';
import {useTranslation} from "react-i18next";
import React, { useState, useEffect } from 'react';
import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { changeTicketInfo } from '../../../utils/RequestBuilder';
import Scrollbars from 'react-custom-scrollbars-2';

function MainAreaCard({ticket, auth, hasManufacturerRole}) {

    const [ ticketData, setTicketData ] = useState(ticket);
    const [ editable, setEditable] = useState(false);
    const [ form ] = Form.useForm();
    const { t, i18n } = useTranslation();
    const { TextArea } = Input;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    const titleSpan = 6
    const contentSpan = 18


    useEffect(() => {
        setTicketData(ticket)
    }, [ticket])

    useEffect(() => {
        form.resetFields()
    }, [ticketData])

   //Show Ticket Information Update Alert
   const [api, contextHolder] = notification.useNotification();
   const openNotification = (placement, status, desc, title, errorCodeString) => {
       api[status]({
         message: title,
         description: 
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
       });
   };
   function showAlert(response) {
       let desc = "";
       let title = "";
       let errorCode = "";
       if (response == 200) {
           title = t('tickets.details.main-area.update-message.title.success');
           desc = t('tickets.details.main-area.update-message.body.success');
           openNotification('topRight', 'success', desc, title, errorCode)
       } else {
           //console.log ('Error')
           title = t('tickets.details.main-area.update-message.title.error');
           desc = t('tickets.details.main-area.update-message.body.error');
           errorCode = '[' + t('tickets.details.main-area.update-message.status-code') + ': ' + response + ']'
           
           openNotification('topRight', 'error', desc, title, errorCode)
       }
   }


   //Update Ticket information in database
   async function saveFormData(auth, path, descValue, troubleValue, titleValue, id) {
    if (descValue == "") {descValue = " "};
    if (troubleValue == "") {troubleValue = " "};
    if (titleValue == "") {titleValue = " "};
    const attributesArray = [
        {attributeName: "description", attributeValue: descValue},
        {attributeName: "troubleshooting", attributeValue: troubleValue},
        {attributeName: "title", attributeValue: titleValue}
    ]
    try {
        const result = await changeTicketInfo(auth, path, attributesArray, id );
        console.log('result', result);
        showAlert(result);
    } catch (error) {
        console.error(error);
        //showAlert(error.status)
    }
}



    return (
        <>
            {contextHolder}
            <Form
                form={form}
                name= "basic"
                initialValues={{
                    description: ticketData.description,
                    troubleshooting: ticketData.troubleshooting,
                    title: ticketData.title
                }}
            >
                <Scrollbars
                    autohide="true"
                    style={{minHeight: "26vh"}}
                >
                    <Card
                        className="tickets-details-card"
                        //headerBg="red"
                        style={{maxHeight: "25vh"}}
                        title={
                            <div>       
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Col
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            alignSelf: 'center'
                                        }}
                                    >
                                        <Form.Item
                                            name="title"
                                            style={{
                                                marginBottom: '0px'
                                            }}
                                        >
                                            <TextArea 
                                                style={{
                                                    resize: 'none',
                                                    fontWeight: 'bold',
                                                    minWidth: '600px',
                                                    padding: '0 0 0 0',
                                                    alignSelf: 'center'}}
                                                //value= {ticketData.description} 
                                                //disabled={!editable}
                                                allowClear={editable} 
                                                bordered={editable}
                                                readOnly={!editable}
                                                autoSize={true} 
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        {(!editable && !hasManufacturerRole) ? (                            
                                            <Button 
                                                type="primary"
                                                onClick={() => setEditable(!editable)}
                                            >
                                                <EditOutlined /> {t('tickets.details.main-area.edit')}
                                            </Button>
                                        ) : (!hasManufacturerRole && (
                                            <Space>
                                                <Button 
                                                    type="primary"
                                                    onClick={() => {
                                                        const formValues = form.getFieldsValue();
                                                        const descValue = formValues.description;
                                                        const troubleValue = formValues.troubleshooting;
                                                        const titleValue = formValues.title;
                                                        setTicketData({
                                                            ...ticketData,
                                                            description: descValue,
                                                            troubleshooting: troubleValue,
                                                            title: titleValue
                                                        })
                                                        //console.log('Ticketdata after save', ticketData)
                                                        saveFormData(auth, "/tickets/", descValue, troubleValue, titleValue, ticket.id);
                                                        //form.resetFields()
                                                        

                                                        setEditable(!editable)}
                                                    }
                                                >
                                                    <SaveOutlined /> {t('tickets.details.main-area.save')}
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        form.resetFields();
                                                        setEditable(!editable)}
                                                    }
                                                >
                                                    <CloseOutlined /> {t('tickets.details.main-area.cancel')}
                                                </Button>
                                            </ Space>
                                        )
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        }
                    >  
                        <div>
                            <Row key='1'>
                                <Col span={titleSpan}>
                                    {t('tickets.details.main-area.description')}
                                </Col>
                                <Col span={contentSpan}>
                                    <Form.Item
                                        name="description"
                                    >
                                        <TextArea 
                                            style={{
                                                resize: 'none',
                                                padding: '0 0 0 0'}}
                                            //value= {ticketData.description} 
                                            //disabled={!editable}
                                            allowClear={editable} 
                                            bordered={editable}
                                            readOnly={!editable}
                                            autoSize={true} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row key='2'>
                                <Col span={titleSpan}>
                                    {t('tickets.details.main-area.solution')}
                                </Col>
                                <Col span ={contentSpan}>
                                    <Form.Item
                                        name="troubleshooting">
                                        <TextArea
                                            style={{
                                                resize: 'none',
                                                padding: '0 0 0 0'
                                            }} 
                                            //disabled={!editable} 
                                            allowClear={editable} 
                                            bordered={editable}
                                            readOnly={!editable}
                                            autoSize={true} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> 
                        </div>
                    </Card>
                </Scrollbars>
            </Form>
        </>
    )
}

export default MainAreaCard;
